import React, { useEffect, useState } from "react";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import "../Styles/MainContent.css";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineDashboardCustomize } from "react-icons/md";

const MainContent = ({ user, switchChecked, selectedDepartment }) => {
	const [recentItems, setRecentItems] = useState([]);
	const [mostUsedItems, setMostUsedItems] = useState([]);
	const [past30DaysItems, setPast30DaysItems] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [departments, setDepartments] = useState({});
	const [error, setError] = useState(null);
	const [userRole, setUserRole] = useState(null);
	const [teamId, setTeamId] = useState(null);
	const [recentMessages, setRecentMessages] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUserData = async () => {
			if (user && user.uid) {
				try {
					const userDocRef = doc(db, "users", user.uid);
					const userDocSnap = await getDoc(userDocRef);
					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						setUserRole(userData.levelType);
						setTeamId(userData.teamId);
					} else {
						console.error("User document does not exist");
					}
				} catch (error) {
					setError("Error fetching user data.");
					console.error("Error fetching user data: ", error);
				}
			}
		};

		fetchUserData();
	}, [user]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsData = {};
				departmentsSnapshot.forEach((doc) => {
					departmentsData[doc.id] = doc.data();
				});
				setDepartments(departmentsData);
			}
		};

		fetchDepartments();
	}, [teamId]);

	useEffect(() => {
		const fetchAssistantsAndFlows = async () => {
			if (user && user.uid && teamId) {
				try {
					let combinedItems = [];

					// Fetch team assistants
					const teamAssistantsQuery = query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", false)
					);
					const personalAssistantsQuery = query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", true),
						where("createdBy", "==", user.uid)
					);
					const [teamAssistantsSnapshot, personalAssistantsSnapshot] =
						await Promise.all([
							getDocs(teamAssistantsQuery),
							getDocs(personalAssistantsQuery),
						]);

					const teamAssistants = teamAssistantsSnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						type: "Team",
						itemType: "Assistant",
					}));
					const personalAssistants = personalAssistantsSnapshot.docs.map(
						(doc) => ({
							...doc.data(),
							id: doc.id,
							type: "Personal",
							itemType: "Assistant",
						})
					);
					combinedItems = [
						...combinedItems,
						...teamAssistants,
						...personalAssistants,
					];

					// Fetch team flows
					const teamFlowsQuery = query(
						collection(db, `teams/${teamId}/flows`),
						where("isPersonal", "==", false)
					);
					const personalFlowsQuery = query(
						collection(db, `teams/${teamId}/flows`),
						where("isPersonal", "==", true),
						where("createdBy", "==", user.uid)
					);
					const [teamFlowsSnapshot, personalFlowsSnapshot] = await Promise.all([
						getDocs(teamFlowsQuery),
						getDocs(personalFlowsQuery),
					]);

					const teamFlows = teamFlowsSnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						type: "Team",
						itemType: "Flow",
					}));
					const personalFlows = personalFlowsSnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						type: "Personal",
						itemType: "Flow",
					}));
					combinedItems = [...combinedItems, ...teamFlows, ...personalFlows];

					// Filter items based on selected department
					const filteredItems =
						selectedDepartment && selectedDepartment.id !== "all"
							? combinedItems.filter(
									(item) =>
										item.departments &&
										item.departments.includes(selectedDepartment.id)
							  )
							: combinedItems;

					// Categorize items
					// Most used based on conversation length (only applicable for assistants)
					const sortedByUsage = filteredItems
						.filter((item) => item.itemType === "Assistant")
						.sort(
							(a, b) =>
								(b.conversation?.length || 0) - (a.conversation?.length || 0)
						)
						.slice(0, 5);
					setMostUsedItems(sortedByUsage);

					// Recently created
					const sortedByCreatedAt = filteredItems
						.filter((item) => item.createdAt)
						.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
					setRecentItems(sortedByCreatedAt);

					// Past 30 days
					const thirtyDaysAgo = new Date();
					thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
					const past30DaysData = sortedByCreatedAt.filter(
						(item) => item.createdAt && item.createdAt.toDate() >= thirtyDaysAgo
					);
					setPast30DaysItems(past30DaysData);

					// Fetch team members
					const membersSnapshot = await getDocs(collection(db, "users"));
					const membersData = membersSnapshot.docs
						.map((doc) => ({
							id: doc.id,
							...doc.data(),
						}))
						.filter((member) => member.teamId === teamId);
					setTeamMembers(membersData);
				} catch (error) {
					setError("Error fetching assistants and flows.");
					console.error("Error fetching data: ", error);
				}
			}
		};

		if (teamId && userRole) {
			fetchAssistantsAndFlows();
		}
	}, [user, teamId, userRole, selectedDepartment]);

	useEffect(() => {
		const fetchRecentChats = async () => {
			if (user && user.uid && teamId) {
				try {
					const chatsRef = collection(db, "teams", teamId, "chats");
					const chatsSnapshot = await getDocs(chatsRef);

					let recentChats = chatsSnapshot.docs
						.map((doc) => {
							const chatData = doc.data();
							// Check if the current user is a participant in this chat
							if (chatData.participants.includes(user.email)) {
								const otherParticipant = chatData.participants.find(
									(p) => p !== user.email
								);
								return {
									id: doc.id,
									otherParticipant: otherParticipant,
									lastUpdated: chatData.lastUpdated,
								};
							}
							return null;
						})
						.filter(Boolean); // Remove any null entries

					// Sort chats by lastUpdated timestamp and take the 5 most recent
					recentChats.sort((a, b) => b.lastUpdated - a.lastUpdated);
					setRecentMessages(recentChats.slice(0, 5));
				} catch (error) {
					setError("Error fetching recent chats.");
					console.error("Error fetching recent chats: ", error);
				}
			}
		};

		fetchRecentChats();
	}, [user, teamId]);

	const renderDepartments = (item) => {
		if (item.type === "Personal") {
			return "Personal";
		}
		if (item.departments && item.departments.length > 0) {
			return item.departments
				.map((deptId) => departments[deptId]?.name)
				.join(", ");
		}
		return "No Department";
	};

	const generateLinkTo = (item) => {
		return item.itemType === "Assistant"
			? `/chat/${item.id}`
			: `/users/${user.uid}/workflows/${item.id}`;
	};

	const renderCard = (item, index) => {
		const departmentColor =
			item.departments && item.departments.length > 0
				? departments[item.departments[0]]?.color
				: "transparent";

		return (
			<div
				key={index}
				className={`card card-color-${switchChecked} card-border`}
				style={{ borderTop: `10px solid ${departmentColor}` }}
			>
				<h3>
					{item.name?.toUpperCase() ||
						item.templateName?.toUpperCase() ||
						"Unnamed"}
				</h3>
				<p>
					{item.itemType === "Assistant" ? "Agent" : item.itemType} ({item.type}
					)
				</p>
				<p>Department: {renderDepartments(item)}</p>
				<Link
					to={generateLinkTo(item)}
					className="main-content-button home-button"
				>
					Continue
				</Link>
			</div>
		);
	};

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<div className="welcome"></div>
			<div className="customize">
				<MdOutlineDashboardCustomize className="create-plus-false" /> Customize
			</div>
			{error && <p className="error">{error}</p>}
			<div className={`main-rows main-rows-${switchChecked}`}>
				<div className="section">
					<h3>Most Used</h3>
					<div className="cards">
						{mostUsedItems.length > 0 ? (
							mostUsedItems.map((item, index) => renderCard(item, index))
						) : (
							<div
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: `2px solid transparent` }}
							>
								<h3>Create New Thread</h3>
								<Link
									to={`/templates`}
									className="main-content-button home-button"
								>
									Create
								</Link>
							</div>
						)}
					</div>
				</div>
				<div className="section">
					<h3>Recently Created</h3>
					<div className="cards">
						{recentItems.length > 0 ? (
							recentItems.map((item, index) => renderCard(item, index))
						) : (
							<div
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: `2px solid transparent` }}
							>
								<h3>Create New Thread</h3>
								<Link
									to={`/templates`}
									className="main-content-button home-button"
								>
									Create
								</Link>
							</div>
						)}
					</div>
				</div>
				<div className="section">
					<h3>Past 30 Days</h3>
					<div className="cards">
						{past30DaysItems.length > 0 ? (
							past30DaysItems.map((item, index) => renderCard(item, index))
						) : (
							<div
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: `2px solid transparent` }}
							>
								<h3>Create New Thread</h3>
								<Link
									to={`/templates`}
									className="main-content-button home-button"
								>
									Create
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={`minor-rows minor-rows-${switchChecked}`}>
				<div className="section" onClick={() => navigate("/team-members")}>
					<h3>My Team</h3>
					<div className="cards">
						{teamMembers.length > 0 ? (
							teamMembers.map((member) => (
								<div
									key={member.id}
									className={`card card-color-${switchChecked} card-border`}
									style={{ borderTop: `2px solid transparent` }}
								>
									<span>{member.email}</span>
								</div>
							))
						) : (
							<div
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: `2px solid transparent` }}
							>
								<h3>No team members found</h3>
							</div>
						)}
					</div>
				</div>
				<div className="section" onClick={() => navigate("/messages")}>
					<h3>My Messages</h3>
					<div className="cards">
						{recentMessages.length > 0 ? (
							recentMessages.map((chat, index) => (
								<div
									key={index}
									className={`card card-color-${switchChecked} card-border`}
									style={{ borderTop: `2px solid transparent` }}
								>
									<span>{chat.otherParticipant}</span>
									<p>{chat.lastUpdated.toDate().toLocaleString()}</p>
								</div>
							))
						) : (
							<div
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: `2px solid transparent` }}
							>
								<h5>No recent conversations</h5>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainContent;
