import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../firebaseConfig";
import {
    signInWithPopup,
    createUserWithEmailAndPassword,
    updateProfile,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Login.css";

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Function to capitalize the first letter of each word in a string
const capitalizeName = (name) => {
    return name.split(" ").map(capitalizeFirstLetter).join(" ");
};

// Common function to set user details in Firestore
const setUserDetails = async (user, firstName, lastName, email, teamId) => {
    const userDocRef = doc(db, "users", user.uid);
    await setDoc(userDocRef, {
        firstName: capitalizeName(firstName),
        lastName: capitalizeName(lastName),
        email,
        teamId,
        accountType: "team",
        levelType: "staff",
    });
};

const JoinTeam = ({ setUser, switchChecked }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    useEffect(() => {
        if (!token) {
            setError("Invalid or missing invitation token.");
        }
    }, [token]);

    const joinTeam = async (user, firstName, lastName) => {
        try {
            const inviteDoc = await getDoc(doc(db, "invites", token));

            if (inviteDoc.exists()) {
                const { email: invitedEmail, teamId } = inviteDoc.data();

                if (user.email.toLowerCase() === invitedEmail.toLowerCase()) {
                    const userDocRef = doc(db, "users", user.uid);
                    const teamDocRef = doc(db, "teams", teamId);

                    // Check if user document exists, if not create it with additional details
                    const userDoc = await getDoc(userDocRef);
                    if (!userDoc.exists()) {
                        await setUserDetails(
                            user,
                            firstName,
                            lastName,
                            user.email,
                            teamId
                        );
                    } else {
                        await updateDoc(userDocRef, {
                            teamId,
                            accountType: "team",
                            levelType: "staff",
                        });
                    }

                    // Check if team document exists, if not create it
                    const teamDoc = await getDoc(teamDocRef);
                    if (!teamDoc.exists()) {
                        await setDoc(teamDocRef, {
                            members: [user.email],
                        });
                    } else {
                        await updateDoc(teamDocRef, {
                            members: arrayUnion(user.email),
                        });
                    }

                    setUser(user);
                    navigate("/");
                } else {
                    setError(
                        "Please log in with the email address you were invited with."
                    );
                }
            } else {
                setError("Invitation not found or expired.");
            }
        } catch (error) {
            setError("Error joining the team. Please try again.");
            console.error("Error joining team:", error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(""); // Clear any existing error messages

        if (!firstName || !lastName) {
            setError("First name and last name are required");
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email.toLowerCase(),
                password
            );
            const user = userCredential.user;
            await updateProfile(user, {
                displayName: `${capitalizeName(firstName)} ${capitalizeName(
                    lastName
                )}`,
            });
            await joinTeam(user, firstName, lastName);
        } catch (error) {
            console.error("Signup error:", error.code, error.message);
            switch (error.code) {
                case "auth/email-already-in-use":
                    setError(
                        "An account with this email already exists. Please use a different email or contact support."
                    );
                    break;
                case "auth/invalid-email":
                    setError(
                        "Invalid email format. Please enter a valid email address."
                    );
                    break;
                case "auth/weak-password":
                    setError(
                        "Password is too weak. Please use a stronger password."
                    );
                    break;
                default:
                    setError(
                        "An error occurred while creating your account. Please try again."
                    );
            }
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const [firstName, lastName] = user.displayName.split(" ");
            await joinTeam(user, firstName, lastName);
        } catch (error) {
            setError("Error logging in with Google.");
            console.error("Error logging in with Google", error);
        }
    };

    return (
        <div className={`loginContent main-color-${switchChecked}`}>
            <img
                src="/bgLogin.jpg"
                alt="backgroundImg"
                className="backgroundImg"
            />
            <div className="SpinFlowTitle">
                <img src="/newLogo512.png" alt="spinFlow Logo" />
            </div>
            <div className={`loginBox card-color-${switchChecked}`}>
                <h2>Join Team</h2>
                {error && <p className="errors">{error}</p>}
                <button className="googleButton" onClick={handleGoogleLogin}>
                    <img
                        src="https://img.icons8.com/color/16/000000/google-logo.png"
                        alt="Google icon"
                    />
                    Continue with Google
                </button>
                <div className="separator">
                    <span>or</span>
                </div>
                <form onSubmit={handleLogin}>
                    <div className="userName">
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            required
                        />
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            required
                        />
                    </div>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                    />
                    <div className="password-container">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                        />
                        <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                            onClick={() => setShowPassword(!showPassword)}
                            className="eye-icon"
                        />
                    </div>
                    <div className="password-container">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password"
                            required
                        />
                        <FontAwesomeIcon
                            icon={showConfirmPassword ? faEye : faEyeSlash}
                            onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                            }
                            className="eye-icon"
                        />
                    </div>
                    <button type="submit">Join Team</button>
                </form>
            </div>
        </div>
    );
};

export default JoinTeam;
