import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Styles/WorkflowList.css";

const WorkflowList = ({ user, switchChecked }) => {
	// eslint-disable-next-line
	const [workflows, setWorkflows] = useState([]);
	// eslint-disable-next-line
	const [templates, setTemplates] = useState([]);
	// eslint-disable-next-line
	const [teamId, setTeamId] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		const loadWorkflows = async () => {
			try {
				// First, get the user's team ID
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);

				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					const teamId = userData.teamId;
					setTeamId(teamId);

					if (teamId) {
						const querySnapshot = await getDocs(
							collection(db, `teams/${teamId}/flows`)
						);
						const loadedWorkflows = [];
						querySnapshot.forEach((doc) => {
							loadedWorkflows.push({ id: doc.id, ...doc.data() });
						});
						setWorkflows(loadedWorkflows);
					} else {
						console.error("User is not part of a team");
					}
				} else {
					console.error("User document not found");
				}
			} catch (error) {
				console.error("Error loading workflows: ", error);
			}
		};

		// Load templates from predefined configurations
		loadWorkflows();
	}, [user.uid]);

	const handleCreateNewWorkflow = async () => {
		try {
			if (!teamId) {
				console.error("User is not part of a team");
				return;
			}
			const newWorkflowRef = await addDoc(
				collection(db, `teams/${teamId}/flows`),
				{
					prompts: [],
					title: "Untitled Workflow",
					createdBy: user.uid,
					createdAt: new Date(),
					isPersonal: true,
					departments: "",
				}
			);
			navigate(`/users/${user.uid}/workflows/${newWorkflowRef.id}`);
		} catch (error) {
			console.error("Error creating new workflow: ", error);
		}
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<h2>Create Custom Flow</h2>
			<div className="create-new-flow-btn">
				<button
					onClick={handleCreateNewWorkflow}
					className="main-content-button create-new-workflow-button"
				>
					Create New Flow
				</button>
			</div>
		</div>
	);
};

export default WorkflowList;
