import React, { useState } from "react";
import { auth, googleProvider, db } from "../firebaseConfig";
import {
	signInWithEmailAndPassword,
	signInWithPopup,
	sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { doc, setDoc } from "firebase/firestore";
import "../Styles/Login.css";

const Login = ({ setUser, switchChecked }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [resetEmail, setResetEmail] = useState("");
	const [showResetForm, setShowResetForm] = useState(false);
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			setUser(userCredential.user);
			navigate("/");
		} catch (error) {
			switch (error.code) {
				case "auth/wrong-password":
					setError("Incorrect password. Please try again.");
					break;
				case "auth/user-not-found":
					setError("No account found with this email.");
					break;
				case "auth/invalid-email":
					setError("Invalid email format.");
					break;
				case "auth/invalid-credential":
					setError("Invalid email or password.");
					break;
				default:
					setError("Error logging in with email and password.");
			}
			console.error("Error logging in with password and email", error);
		}
	};

	const handleGoogleLogin = async () => {
		try {
			// Attempt to sign in with Google
			const result = await signInWithPopup(auth, googleProvider);
			const user = result.user;
			const userDocRef = doc(db, "users", user.uid);
			console.log(user);
			const isNewUser = result._tokenResponse.isNewUser;
			console.log(isNewUser);
			if (!isNewUser) {
				setUser(user);
				navigate("/");
			} else {
				await setDoc(userDocRef, {
					firstName: user.displayName.split(" ")[0],
					lastName: user.displayName.split(" ")[1],
					email: user.email,
				});
				setUser(user);
				navigate("/");
			}
		} catch (error) {
			if (error.code === "auth/popup-closed-by-user") {
				setError("Google sign-in was cancelled.");
			} else {
				setError("Error logging in with Google.");
				console.error("Error logging in with Google:", error);
			}
		}
	};

	const handleResetPassword = async (e) => {
		e.preventDefault();
		if (!resetEmail) {
			setError("Please enter your email address.");
			return;
		}
		try {
			await sendPasswordResetEmail(auth, resetEmail);
			alert("Password reset email sent. Please check your inbox.");
			setResetEmail("");
			setShowResetForm(false);
		} catch (error) {
			console.error("Error sending password reset email:", error);
			setError("Failed to send password reset email. Please try again.");
		}
	};

	return (
		<div className={`loginContent main-color-${switchChecked}`}>
			<img src="/bgLogin.jpg" alt="backgroundImg" className="backgroundImg" />
			<div className="SpinFlowTitle">
				<img src="/newLogo512.png" alt="spinFlow Logo" />
			</div>
			<div className={`loginBox card-color-${switchChecked}`}>
				<h2>Login</h2>
				<button className="googleButton" onClick={handleGoogleLogin}>
					<img
						src="https://img.icons8.com/color/16/000000/google-logo.png"
						alt="Google icon"
					/>
					Login with Google
				</button>

				{/* <button className="googleButton" onClick={handleGoogleLogin}>
					<img
						src="https://img.icons8.com/color/16/000000/microsoft.png"
						alt="Microsoft icon"
					/>
					Login with Microsoft
				</button> */}
				<div className="separator">
					<span>or</span>
				</div>
				{!showResetForm ? (
					<form onSubmit={handleLogin}>
						{error && <p className="errors">{error}</p>}
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Email"
							required
						/>
						<div className="password-container">
							<input
								type={showPassword ? "text" : "password"}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Password"
								required
							/>
							<FontAwesomeIcon
								icon={showPassword ? faEye : faEyeSlash}
								onClick={() => setShowPassword(!showPassword)}
								className="eye-icon"
							/>
						</div>
						<button type="submit">Login</button>
					</form>
				) : (
					<form onSubmit={handleResetPassword}>
						{error && <p className="errors">{error}</p>}
						<input
							type="email"
							value={resetEmail}
							onChange={(e) => setResetEmail(e.target.value)}
							placeholder="Enter your email"
							required
						/>
						<button type="submit">Send Reset Email</button>
					</form>
				)}
				<p style={{ marginTop: "15px" }}>
					{!showResetForm ? (
						<>
							Forgot your password?{" "}
							<button
								onClick={() => setShowResetForm(true)}
								className="main-content-button"
								style={{ padding: "5px" }}
							>
								Reset Password
							</button>
						</>
					) : (
						<button
							onClick={() => setShowResetForm(false)}
							className="main-content-button"
							style={{ padding: "5px" }}
						>
							Back to Login
						</button>
					)}
				</p>
				<p style={{ marginTop: "15px" }}>
					Don't have an account?{" "}
					<a
						href="/signup"
						className="main-content-button"
						style={{ padding: "5px" }}
					>
						Sign up
					</a>
				</p>
			</div>
		</div>
	);
};

export default Login;
