export const model = "gpt-4o"; // Specify the model to use
export const viewType = "prompt"; // Default view type
export const templateName = "Post-Launch Performance Analyst Flow"; // Template name for the assistant
export const defaultTemplateName = "Post-Launch Performance Analyst Flow";

// Array of prompt objects
export const prompts = [
	{
		id: "prompt-1",
		title: "Sales and Engagement Data Collection",
		content: `Your task is to collect and analyze post-launch data related to sales and customer engagement. Follow these steps:
1. Gather real-time sales data, product usage stats, and customer feedback from all relevant channels.
2. Track user behavior on product pages, email campaigns, and social media interactions.
3. Identify key metrics such as revenue, customer engagement, and feature adoption rates.
4. Present the data in a clear, visual format (charts, graphs, etc.) for easy interpretation.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "action",
		url: "",
	},
	{
		id: "prompt-2",
		title: "Performance Metrics Evaluation",
		content: `Your task is to evaluate key performance indicators (KPIs) based on collected data. Follow these steps:
1. Analyze KPIs such as revenue growth, customer satisfaction, and product feature usage.
2. Compare the actual performance against pre-launch projections.
3. Identify underperforming areas and potential causes (e.g., low feature adoption, high churn rate).
4. Provide recommendations to address performance gaps.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-3",
		title: "Customer Feedback Analysis",
		content: `Your task is to analyze customer feedback and identify recurring themes. Follow these steps:
1. Collect customer feedback from reviews, social media, and support inquiries.
2. Use AI to identify common issues, pain points, or feature requests.
3. Summarize the most frequent and impactful feedback in a report format.
4. Provide actionable insights to improve the product based on customer input.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-4",
		title: "Optimization Suggestions",
		content: `Your task is to provide recommendations for improving the product and post-launch marketing strategies. Follow these steps:
1. Based on customer feedback and performance metrics, suggest changes to product features or enhancements.
2. Recommend marketing adjustments (e.g., updated messaging, new campaign strategies) to boost engagement.
3. Present actionable steps to refine both the product and its promotion in the market.
4. Summarize your suggestions in a structured report with clear next steps for the product and marketing teams.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
];
