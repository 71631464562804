import React, { useState } from "react";
import { auth, googleProvider, db } from "../firebaseConfig";
import {
	createUserWithEmailAndPassword,
	signInWithPopup,
	updateProfile,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import "../Styles/Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Function to capitalize the first letter of each word in a string
const capitalizeName = (name) => {
	return name.split(" ").map(capitalizeFirstLetter).join(" ");
};

const Signup = ({ setUser, switchChecked }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleSignup = async (e) => {
		e.preventDefault();
		if (!firstName || !lastName) {
			setError("First name and last name are required");
			return;
		}
		if (password !== confirmPassword) {
			setError("Passwords do not match");
			return;
		}
		if (password === confirmPassword && password.length < 8) {
			setError("Password must be over 8 characters long");
			return;
		}

		const capitalizedFirstName = capitalizeName(firstName);
		const capitalizedLastName = capitalizeName(lastName);

		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;
			// Set display name in Firebase Auth profile
			await updateProfile(user, {
				displayName: `${capitalizedFirstName} ${capitalizedLastName}`,
			});
			// Save user details in Firestore
			await setDoc(doc(db, "users", user.uid), {
				firstName: capitalizedFirstName,
				lastName: capitalizedLastName,
				email,
			});
			setUser(user);
			navigate("/");
		} catch (error) {
			if (error.code === "auth/email-already-in-use") {
				setError("The email address is already in use. Please login.");
			} else {
				setError("Error signing up with email and password");
			}
			console.error("Error signing up with email and password", error);
		}
	};

	const handleGoogleSignup = async () => {
		try {
			const userCredential = await signInWithPopup(auth, googleProvider);
			const user = userCredential.user;
			const { displayName, email } = user;
			const [firstName, lastName] = displayName.split(" ");
			const capitalizedFirstName = capitalizeName(firstName);
			const capitalizedLastName = capitalizeName(lastName);
			// Save user details in Firestore
			await setDoc(doc(db, "users", user.uid), {
				firstName: capitalizedFirstName,
				lastName: capitalizedLastName,
				email,
			});
			setUser(user);
			navigate("/");
		} catch (error) {
			console.error("Error signing up with Google", error);
		}
	};

	return (
		<div className={`loginContent main-color-${switchChecked}`}>
			<img src="/bgLogin.jpg" alt="backgroundImg" className="backgroundImg" />
			<div className="SpinFlowTitle">
				<img src="/newLogo512.png" alt="spinFlow Logo" />
			</div>
			<div className={`loginBox card-color-${switchChecked}`}>
				<h2>Sign up</h2>
				<button className="googleButton" onClick={handleGoogleSignup}>
					<img
						src="https://img.icons8.com/color/16/000000/google-logo.png"
						alt="Google icon"
					/>
					Sign up with Google
				</button>
				{/* <button className="googleButton" onClick={handleGoogleSignup}>
					<img
						src="https://img.icons8.com/color/16/000000/microsoft.png"
						alt="Microsoft icon"
					/>
					Login with Microsoft
				</button> */}
				<div className="separator">
					<span>or</span>
				</div>
				<form onSubmit={handleSignup}>
					{error && <p className="errors">{error}</p>}
					<div className="userName">
						<input
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							placeholder="First Name"
							required
						/>
						<input
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							placeholder="Last Name"
							required
						/>
					</div>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						required
					/>
					<div className="password-container">
						<input
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							required
						/>
						<FontAwesomeIcon
							icon={showPassword ? faEye : faEyeSlash}
							onClick={() => setShowPassword(!showPassword)}
							className="eye-icon"
						/>
					</div>
					<div className="password-container">
						<input
							type={showConfirmPassword ? "text" : "password"}
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							placeholder="Confirm Password"
							required
						/>
						<FontAwesomeIcon
							icon={showConfirmPassword ? faEye : faEyeSlash}
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
							className="eye-icon"
						/>
					</div>
					<button type="submit">Sign Up</button>
				</form>
				<p style={{ marginTop: "15px" }}>
					Already have an account?{" "}
					<a
						href="/login"
						className="main-content-button"
						style={{ padding: "5px" }}
					>
						Log in
					</a>
				</p>
			</div>
		</div>
	);
};

export default Signup;
