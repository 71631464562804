export const model = "gpt-4o"; // Specify the model to use
export const viewType = "prompt"; // Default view type
export const templateName = "Competitor Analysis Flow"; // Template name for the assistant
export const defaultTemplateName = "Competitor Analysis Flow";
// Array of prompt objects
export const prompts = [
    {
        id: "prompt-1",
        title: "Competitor Site Scraper",
        content: "",
        model: "gpt-4o",
        knowledgeBases: [],
        fineTunedModel: "",
        viewType: "action",
        url: "",
    },
    {
        id: "prompt-2",
        title: "Competitor Site Analysis",
        content: `Your task is to analyze the competitor's website content provided and extract the main information. Follow these steps:
1. Review the website content thoroughly.
2. Identify and summarize the following key elements:
   a. Main products or services offered
   b. Unique selling propositions (USPs)
   c. Target audience
   d. Pricing strategy (if available)
   e. Key features or benefits highlighted
   f. Content structure and organization
3. Analyze the website's:
   a. Tone and style of communication
   b. Use of visuals and multimedia
   c. Call-to-action (CTA) strategies
   d. Navigation and user experience
4. Identify any notable marketing strategies or campaigns.
5. Examine customer testimonials or reviews (if present).
6. Look for any partnerships, certifications, or awards mentioned.
7. Analyze their blog or resources section (if available) to understand their content marketing strategy.
8. Identify their social media presence and key messaging on those platforms.
9. Summarize the overall strengths and potential weaknesses of the competitor's online presence.
10. Provide actionable insights that could be used to improve our own website or marketing strategy based on this analysis.
Present your analysis in a clear, structured format with headings for each main point. Use bullet points where appropriate for clarity. Conclude with a brief overall assessment of the competitor's online strategy and positioning in the market.`,
        model: "gpt-4o",
        knowledgeBases: [],
        fineTunedModel: "",
        viewType: "prompt",
    },
];
