import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import "../Styles/Header.css";

const DepartmentDropdown = ({ departments, value, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSelect = (department) => {
		onChange(department);
		setIsOpen(false);
	};

	// Add an "All" option to the departments list
	const allDepartments = [
		{ id: "all", name: "All Departments", color: "#FFFFFF" },
		...departments,
	];

	return (
		<div className="color-dropdown headerDropdown" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="color-dropdown-toggle header-department-dropdown"
			>
				{value ? (
					<>
						<span
							className="color-sample"
							style={{ backgroundColor: value.color }}
						></span>
						{value.name}
					</>
				) : (
					"Department Key"
				)}
			</button>

			{isOpen && (
				<ul className="color-dropdown-menu header-color-dropdown-menu">
					{allDepartments.map((dept) => (
						<li
							key={dept.id}
							onClick={() => handleSelect(dept)}
							className="dropdown-item"
						>
							<span
								className="color-sample"
								style={{ backgroundColor: dept.color }}
							></span>
							{dept.name}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

const Header = ({
	user,
	switchChecked,
	selectedDepartment,
	setSelectedDepartment,
}) => {
	const [departments, setDepartments] = useState([]);

	useEffect(() => {
		const fetchDepartments = async () => {
			try {
				const userDoc = await getDoc(doc(db, `users/${user.uid}`));
				if (userDoc.exists()) {
					const teamId = userDoc.data().teamId;

					const departmentsSnapshot = await getDocs(
						collection(db, `teams/${teamId}/departments`)
					);
					setDepartments(
						departmentsSnapshot.docs.map((doc) => ({
							id: doc.id,
							...doc.data(),
						}))
					);
				}
			} catch (error) {
				console.error("Error fetching departments: ", error);
			}
		};

		if (user?.uid) {
			fetchDepartments();
		}
	}, [user]);

	return (
		<div
			className={`header main-color-${switchChecked} header-color-${switchChecked}`}
		>
			<div className="title">
				<Link to="/" className="title-link">
					<img
						src={`${switchChecked ? "/darkLogo512.png" : "/newLogo512.png"}`}
						alt="logo"
						className="mainlogo"
					/>
				</Link>
			</div>

			<div>
				{departments.length > 0 ? (
					<DepartmentDropdown
						departments={departments}
						value={selectedDepartment}
						onChange={setSelectedDepartment}
					/>
				) : (
					<p>No Departments Available</p>
				)}
			</div>
		</div>
	);
};

export default Header;
