import React, { useState } from "react";
import { marked } from "marked";

const Documentation = ({ user, switchChecked }) => {
	const [releaseNotes, setReleaseNotes] = useState("");
	const [showReleaseNotes, setShowReleaseNotes] = useState(false);

	const handleButtonClick = () => {
		// Toggle the visibility of the release notes
		setShowReleaseNotes(!showReleaseNotes);

		if (!releaseNotes) {
			// Only fetch the release notes if they haven't been fetched yet
			fetch("/releaseNotes.txt")
				.then((response) => response.text())
				.then((data) => {
					// Convert markdown to HTML using marked
					const htmlContent = marked(data);
					setReleaseNotes(htmlContent);
				})
				.catch((error) => {
					console.error("Error fetching the release notes:", error);
					setReleaseNotes("<p>Failed to load release notes.</p>");
				});
		}
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<button
				className="main-content-button home-button"
				onClick={handleButtonClick}
			>
				Release Notes
			</button>

			{showReleaseNotes && (
				<div
					className="release-notes-content"
					dangerouslySetInnerHTML={{ __html: releaseNotes }}
				></div>
			)}
		</div>
	);
};

export default Documentation;
