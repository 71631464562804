import React, { useState, useEffect, useRef } from "react";
import SpeechRecognition, {
	useSpeechRecognition,
} from "react-speech-recognition";
import {
	collection,
	getDoc,
	doc,
	setDoc,
	getDocs,
	query,
	where,
} from "firebase/firestore";
import { db, storage } from "../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";

const InterviewBot = ({ user, switchChecked }) => {
	const [questionIndex, setQuestionIndex] = useState(0);
	const [responses, setResponses] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [role, setRole] = useState("");
	const [interviewStarted, setInterviewStarted] = useState(false);
	const [waitingForResponse, setWaitingForResponse] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState("");
	// eslint-disable-next-line
	const [userResponse, setUserResponse] = useState("");
	const [showReview, setShowReview] = useState(false);
	const [editedResponses, setEditedResponses] = useState([]);
	const [fileName, setFileName] = useState("");
	const silenceTimeoutRef = useRef(null);

	const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
		useSpeechRecognition();

	useEffect(() => {
		checkForExistingInterview();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (interviewStarted && waitingForResponse && transcript) {
			if (silenceTimeoutRef.current) {
				clearTimeout(silenceTimeoutRef.current);
			}

			silenceTimeoutRef.current = setTimeout(() => {
				console.log("User stopped speaking, processing response");
				setResponses((prev) => [
					...prev,
					{ question: questions[questionIndex], answer: transcript },
				]);
				setQuestionIndex((prev) => prev + 1);
				resetTranscript();
				setUserResponse(transcript);
				setWaitingForResponse(false);
				silenceTimeoutRef.current = null;
				SpeechRecognition.stopListening();
			}, 4000);
		}
	}, [
		transcript,
		waitingForResponse,
		interviewStarted,
		questions,
		questionIndex,
		resetTranscript,
	]);

	useEffect(() => {
		if (
			interviewStarted &&
			!waitingForResponse &&
			questionIndex < questions.length
		) {
			const currentQuestion = questions[questionIndex];
			setCurrentQuestion(currentQuestion);
			setUserResponse("");
			SpeechRecognition.abortListening();
			textToSpeech(currentQuestion);
		} else if (interviewStarted && questionIndex >= questions.length) {
			handleStopInterview();
		}
		// eslint-disable-next-line
	}, [questionIndex, questions, interviewStarted, waitingForResponse]);

	const checkForExistingInterview = async () => {
		try {
			const q = query(
				collection(db, `users/${user.uid}/knowledgebase`),
				where("form", "==", "Interview")
			);
			const querySnapshot = await getDocs(q);
			if (!querySnapshot.empty) {
				const doc = querySnapshot.docs[0];
				const data = doc.data();
				setFileName(data.name);
				setRole(data.role);
				await loadQuestions(data.role);
				await handleResumeInterview(data.name);
			}
		} catch (error) {
			console.error("Error checking for existing interview:", error);
		}
	};

	const loadQuestions = async (selectedRole) => {
		const response = await fetch(
			`${process.env.PUBLIC_URL}/questions/${selectedRole}.txt`
		);
		const text = await response.text();
		const questionLines = text.split("\n").filter((line) => line.trim() !== "");
		setQuestions(questionLines);
	};

	const handleRoleChange = async (event) => {
		const selectedRole = event.target.value;
		setRole(selectedRole);
		await loadQuestions(selectedRole);
		setQuestionIndex(0);
		setResponses([]);
		setInterviewStarted(false);
		setWaitingForResponse(false);
		setFileName("");
	};

	const textToSpeech = async (text) => {
		try {
			const apiKey = process.env.REACT_APP_GOOGLE_TTS_API_KEY;
			const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

			const response = await axios.post(url, {
				input: { text },
				voice: {
					languageCode: "en-US",
					name: "en-US-Standard-I",
					ssmlGender: "FEMALE",
				},
				audioConfig: {
					audioEncoding: "MP3",
					speakingRate: 1.0,
					pitch: 0.0,
				},
			});

			const audioContent = response.data.audioContent;
			const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
			audio.onended = () => {
				setWaitingForResponse(true);
				try {
					SpeechRecognition.startListening({ continuous: false });
				} catch (error) {
					console.error("Error starting listening: ", error);
				}
			};
			audio.play();
		} catch (error) {
			console.error(
				"Error in textToSpeech:",
				error.response ? error.response.data : error.message
			);
		}
	};

	const handleStartInterview = () => {
		setInterviewStarted(true);
		setQuestionIndex(0);
		setResponses([]);
	};

	const handleStopInterview = () => {
		setInterviewStarted(false);
		setShowReview(true);
		setEditedResponses([...responses]);
	};

	const handleSaveProgress = async () => {
		const content = editedResponses
			.map((r) => `Q: ${r.question}\nA: ${r.answer}\n`)
			.join("\n");
		const blob = new Blob([content], { type: "text/plain" });
		const newFileName = fileName || `${role}-interview.txt`;
		const storageRef = ref(
			storage,
			`users/${user.uid}/knowledgebase/${newFileName}`
		);

		try {
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);

			const docRef = doc(db, `users/${user.uid}/knowledgebase`, newFileName);
			await setDoc(
				docRef,
				{
					name: newFileName,
					file: downloadURL,
					createdAt: new Date(),
					updatedAt: new Date(),
					uploadedBy: user.uid,
					role: role,
					lastQuestionIndex: questionIndex - 1,
					form: "Interview",
					isTeamFile: false,
				},
				{ merge: true }
			);

			setFileName(newFileName);
			alert("Interview progress saved. You can resume later.");
			setShowReview(false);
			setInterviewStarted(false);
		} catch (error) {
			console.error("Error saving progress: ", error);
			alert("Error saving progress. Please try again.");
		}
	};

	const handleResumeInterview = async (fileNameToResume) => {
		try {
			const docRef = doc(
				db,
				`users/${user.uid}/knowledgebase`,
				fileNameToResume
			);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const data = docSnap.data();
				setRole(data.role);
				await loadQuestions(data.role);

				const storageRef = ref(storage, data.file);
				const url = await getDownloadURL(storageRef);
				const response = await fetch(url);
				const text = await response.text();

				const savedResponses = text.split("\n\n").map((block) => {
					const [question, answer] = block.split("\n");
					return {
						question: question.replace("Q: ", ""),
						answer: answer.replace("A: ", ""),
					};
				});

				setResponses(savedResponses);
				setEditedResponses(savedResponses);

				const nextQuestionIndex = data.lastQuestionIndex + 1;
				setQuestionIndex(nextQuestionIndex);
				setCurrentQuestion(questions[nextQuestionIndex]);

				setInterviewStarted(true);
				setFileName(fileNameToResume);
			}
		} catch (error) {
			console.error("Error resuming interview: ", error);
			alert("Error resuming interview. Please start a new one.");
		}
	};

	const handleEditResponse = (index, newAnswer) => {
		setEditedResponses((prev) =>
			prev.map((r, i) => (i === index ? { ...r, answer: newAnswer } : r))
		);
	};

	if (!browserSupportsSpeechRecognition) {
		return <span>Browser doesn't support speech recognition.</span>;
	}

	return (
		<div
			className={`main-content main-color-${switchChecked} interview-main-content`}
		>
			<h1>Interview Bot</h1>
			{!interviewStarted && !fileName && (
				<div>
					<label htmlFor="role-select">Select Interview Role:</label>
					<select id="role-select" value={role} onChange={handleRoleChange}>
						<option value="">--Please choose a role--</option>
						<option value="ceo">CEO</option>
						<option value="managers">Managers</option>
						<option value="staff">Staff</option>
					</select>
				</div>
			)}
			{role && !interviewStarted && !fileName && (
				<button onClick={handleStartInterview} className="scrape-button">
					Start New Interview
				</button>
			)}
			{fileName && !interviewStarted && (
				<button
					onClick={() => handleResumeInterview(fileName)}
					className="scrape-button"
				>
					Resume Interview
				</button>
			)}
			{interviewStarted && (
				<>
					<button onClick={handleStopInterview} className="scrape-button">
						Stop Interview
					</button>
					<p>Current Question: {currentQuestion}</p>
					<p>Listening for response...</p>
					{/* <p>User Response: {userResponse}</p> */}
				</>
			)}
			{showReview && (
				<div className="review-modal">
					<h2>Review Your Responses</h2>
					{editedResponses.map((r, index) => (
						<div key={index}>
							<p>Q: {r.question}</p>
							<textarea
								value={r.answer}
								onChange={(e) => handleEditResponse(index, e.target.value)}
							/>
						</div>
					))}
					<button onClick={handleSaveProgress}>Save Progress</button>
					<button onClick={() => setShowReview(false)}>Cancel</button>
				</div>
			)}
			<div className="response-history">
				<h3>Interview Progress:</h3>
				{responses.map((response, index) => (
					<div key={index} className="response-item">
						<p>
							<strong>Q: {response.question}</strong>
						</p>
						<p>A: {response.answer}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default InterviewBot;
