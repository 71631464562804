import React, { useState, useEffect, useCallback } from "react";
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Grid,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { db, storage } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	query,
	doc,
	getDoc,
	updateDoc,
	deleteDoc,
	where,
	setDoc,
} from "firebase/firestore";
import {
	ref,
	getDownloadURL,
	deleteObject,
	uploadBytes,
	getMetadata,
} from "firebase/storage";
import "./Styles/KnowledgeBase.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileLines,
	faFloppyDisk,
	faTimes,
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFileCsv,
	faFileAudio,
	faFileVideo,
	faFileAlt,
	faShareAlt,
	faTrashAlt,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";

const AllKnowledgeBase = ({ user, switchChecked, selectedDepartment }) => {
	const [files, setFiles] = useState([]);
	const [teamId, setTeamId] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [popupContent, setPopupContent] = useState(null);
	const [popupType, setPopupType] = useState("");
	const [editContent, setEditContent] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [currentFileId, setCurrentFileId] = useState(null);
	const [currentFileName, setCurrentFileName] = useState("");
	const [teamMembers, setTeamMembers] = useState([]);
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [selectedMembers, setSelectedMembers] = useState([]);
	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [currentFile, setCurrentFile] = useState(null);
	const [currentFileSharedWith, setCurrentFileSharedWith] = useState([]);
	const [departments, setDepartments] = useState([]);
	// eslint-disable-next-line
	const [userDepartments, setUserDepartments] = useState([]);
	// eslint-disable-next-line
	const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState("");
	const [showSharedWithSection, setShowSharedWithSection] = useState(false);
	const [loading, setLoading] = useState(true);
	const [viewMode, setViewMode] = useState("cards");

	const fetchFiles = useCallback(async () => {
		try {
			const userDoc = await getDoc(doc(db, `users/${user.uid}`));
			if (userDoc.exists()) {
				const userData = userDoc.data();
				setTeamId(userData.teamId);
				setUserDepartments(userData.departments || []);

				// Fetch personal files
				const personalFilesQuery = query(
					collection(db, `users/${user.uid}/knowledgebase`),
					where("uploadedBy", "==", user.uid)
				);
				const personalFilesSnapshot = await getDocs(personalFilesQuery);
				const personalFilesList = await Promise.all(
					personalFilesSnapshot.docs.map(async (doc) => {
						const data = doc.data();
						const storageRef = ref(storage, data.file);
						const metadata = await getMetadata(storageRef);
						return {
							id: doc.id,
							...data,
							isPersonal: true,
							size: metadata.size,
						};
					})
				);

				// Fetch team files uploaded by the user
				let teamFilesList = [];
				if (userData.teamId) {
					const teamFilesQuery = query(
						collection(db, `teams/${userData.teamId}/knowledgebase`),
						where("uploadedBy", "==", user.uid)
					);
					const teamFilesSnapshot = await getDocs(teamFilesQuery);
					teamFilesList = await Promise.all(
						teamFilesSnapshot.docs.map(async (doc) => {
							const data = doc.data();
							const storageRef = ref(storage, data.file);
							const metadata = await getMetadata(storageRef);
							return {
								id: doc.id,
								...data,
								isPersonal: false,
								size: metadata.size,
							};
						})
					);
				}

				// Combine personal and team files
				const allFiles = [...personalFilesList, ...teamFilesList];
				// Remove duplicates based on file name
				const uniqueFiles = allFiles.reduce((acc, current) => {
					const x = acc.find((item) => item.name === current.name);
					if (!x) {
						return acc.concat([current]);
					} else {
						return acc;
					}
				}, []);
				setLoading(false);
				setFiles(uniqueFiles);
			}
		} catch (error) {
			console.error("Error fetching files: ", error);
		}
	}, [user.uid]);

	useEffect(() => {
		fetchFiles();
	}, [fetchFiles]);

	useEffect(() => {
		const fetchTeamMembers = async () => {
			if (teamId) {
				const teamMembersQuery = query(
					collection(db, "users"),
					where("teamId", "==", teamId)
				);
				const teamMembersSnapshot = await getDocs(teamMembersQuery);
				const teamMembersList = teamMembersSnapshot.docs
					.map((doc) => ({
						id: doc.id,
						email: doc.data().email,
					}))
					.filter((member) => member.email !== user.email);
				setTeamMembers(teamMembersList);
			}
		};
		fetchTeamMembers();
	}, [teamId, user.email]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				setDepartments(
					departmentsSnapshot.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}))
				);
			}
		};

		if (teamId) {
			fetchDepartments();
		}
	}, [teamId]);

	const handleShareFile = (file) => {
		if (!teamId) {
			alert("You are not part of a team. Unable to share file.");
			return;
		}
		setCurrentFile(file);
		setIsShareModalOpen(true);
	};

	const handleShareConfirm = async () => {
		try {
			const sharedEmails = selectedMembers.map((member) => member.email);
			const sharedDepartmentIds = selectedDepartments.map((dept) => dept.id);

			const teamFileRef = doc(
				db,
				`teams/${teamId}/knowledgebase`,
				currentFile.id
			);
			const teamFileDoc = await getDoc(teamFileRef);

			if (teamFileDoc.exists()) {
				// Update existing team file
				await updateDoc(teamFileRef, {
					sharedWith: [
						...new Set([
							...teamFileDoc.data().sharedWith,
							...sharedEmails,
							user.email,
						]),
					],
					sharedDepartments: [
						...new Set([
							...(teamFileDoc.data().sharedDepartments || []),
							...sharedDepartmentIds,
						]),
					],
				});
			} else {
				// Create new team file
				await setDoc(teamFileRef, {
					...currentFile,
					sharedWith: [...sharedEmails, user.email],
					sharedDepartments: sharedDepartmentIds,
					sharedBy: user.email,
				});
			}

			// Update personal knowledgebase
			const personalFileRef = doc(
				db,
				`users/${user.uid}/knowledgebase`,
				currentFile.id
			);
			await updateDoc(personalFileRef, {
				sharedWith: [...sharedEmails, user.email],
				sharedDepartments: sharedDepartmentIds,
			});

			alert("File shared successfully!");
			setIsShareModalOpen(false);
			setSelectedMembers([]);
			setSelectedDepartments([]);
			fetchFiles();
		} catch (error) {
			console.error("Error sharing file: ", error);
			alert("Error sharing file. Please try again.");
		}
	};

	const handleDeleteFile = async (file) => {
		if (!window.confirm("Are you sure you want to delete this file?")) {
			return;
		}

		try {
			if (file.isPersonal) {
				await deleteDoc(doc(db, `users/${user.uid}/knowledgebase`, file.id));

				// Also remove from team knowledgebase if it exists
				const teamFileRef = doc(db, `teams/${teamId}/knowledgebase`, file.id);
				const teamFileDoc = await getDoc(teamFileRef);
				if (teamFileDoc.exists()) {
					await deleteDoc(teamFileRef);
				}

				// Delete the file from storage
				const storageRef = ref(storage, file.file);
				await deleteObject(storageRef);
			} else {
				// For team files, just remove the user from sharedWith
				const teamFileRef = doc(db, `teams/${teamId}/knowledgebase`, file.id);
				const teamFileDoc = await getDoc(teamFileRef);
				if (teamFileDoc.exists()) {
					const updatedSharedWith = teamFileDoc
						.data()
						.sharedWith.filter((email) => email !== user.email);
					if (updatedSharedWith.length > 0) {
						await updateDoc(teamFileRef, {
							sharedWith: updatedSharedWith,
						});
					} else {
						await deleteDoc(teamFileRef);
					}
				}
			}

			alert("File deleted successfully!");
			fetchFiles();
		} catch (error) {
			console.error("Error deleting file: ", error);
			alert("Error deleting file. Please try again.");
		}
	};

	const handleNameClick = async (file) => {
		setCurrentFile(file);
		setPopupContent(file.file);
		setIsPopupOpen(true);
		setCurrentFileSharedWith(file.sharedWith || []);
		const fileType = file.name.split(".").pop().toLowerCase();
		if (fileType === "txt") {
			setPopupType("text");
			setIsEditing(true);
			setCurrentFileId(file.id);
			setCurrentFileName(file.name);
			try {
				const response = await fetch(file.file);
				const text = await response.text();
				setEditContent(text);
			} catch (error) {
				console.error("Error fetching file content: ", error);
			}
		} else if (fileType === "pdf") {
			setPopupType("pdf");
		} else if (fileType.match(/(jpg|jpeg|png|gif)/)) {
			setPopupType("image");
		} else {
			setPopupType("other");
		}
	};

	const closePopup = () => {
		setIsPopupOpen(false);
		setPopupContent(null);
		setIsEditing(false);
		setEditContent("");
		setCurrentFileSharedWith([]);
	};

	const handleSave = async () => {
		try {
			const blob = new Blob([editContent], { type: "text/plain" });
			const storageRef = ref(
				storage,
				`users/${user.uid}/personal/${currentFileName}`
			);
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);
			const metadata = await getMetadata(storageRef);

			const fileRef = doc(db, `users/${user.uid}/knowledgebase`, currentFileId);
			await updateDoc(fileRef, {
				file: downloadURL,
				updatedAt: new Date(),
				size: metadata.size,
			});

			setIsEditing(false);
			setPopupContent(null);
			setIsPopupOpen(false);
			fetchFiles();
			alert("File updated successfully!");
		} catch (error) {
			console.error("Error saving file: ", error);
			alert("Error updating file. Please try again.");
		}
	};

	const getFileIcon = (fileName) => {
		const fileType = fileName.split(".").pop().toLowerCase();
		switch (fileType) {
			case "pdf":
				return faFilePdf;
			case "doc":
			case "docx":
				return faFileWord;
			case "xls":
			case "xlsx":
				return faFileExcel;
			case "csv":
				return faFileCsv;
			case "txt":
			case "md":
				return faFileAlt;
			case "mp3":
			case "wav":
				return faFileAudio;
			case "mp4":
			case "mov":
			case "avi":
				return faFileVideo;
			default:
				return faFileLines;
		}
	};

	const getFileColor = (fileName) => {
		if (fileName.sharedDepartments && fileName.sharedDepartments.length > 0) {
			const departmentId = fileName.sharedDepartments[0];
			const department = departments.find((dept) => dept.id === departmentId);
			if (department && department.color) {
				return department.color;
			}
		}
		return "black";
	};

	const getCardStyle = (file) => {
		if (file.sharedDepartments && file.sharedDepartments.length > 0) {
			const departmentId = file.sharedDepartments[0];
			const department = departments.find((dept) => dept.id === departmentId);
			if (department && department.color) {
				return {
					borderTop: `10px solid ${department.color}`,
				};
			}
		}
		return {
			display: "flex",
			flexDirection: "column",
			height: "300px",
			borderTop: "10px solid transparent",
		};
	};

	const formatFileSize = (bytes) => {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
	};

	const filteredFiles = files
		.filter((file) =>
			file.name.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.filter(
			(file) =>
				!selectedDepartment ||
				selectedDepartment.id === "all" ||
				(file.sharedDepartments &&
					file.sharedDepartments.includes(selectedDepartment.id))
		);
	// eslint-disable-next-line
	const handleDepartmentFilterChange = (event) => {
		setSelectedDepartmentFilter(event.target.value);
	};

	const renderCardView = () => (
		<Grid container spacing={4} style={{ padding: "20px" }}>
			{filteredFiles.map((file) => (
				<Grid item xs={12} sm={6} md={4} key={file.id}>
					<Card className={`card-color-${switchChecked} card-border`}>
						<CardMedia
							component="div"
							style={{
								...getCardStyle(file),
								height: "140px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<FontAwesomeIcon
								icon={getFileIcon(file.name)}
								style={{
									color: getFileColor(file),
									fontSize: "3rem",
									background: "white",
									padding: "10px",
									borderRadius: "35px",
								}}
							/>
						</CardMedia>
						<CardContent
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								flexGrow: 1,
								color: switchChecked ? "black" : "white",
							}}
						>
							<Typography
								onClick={() => handleNameClick(file)}
								style={{
									cursor: "pointer",
									textAlign: "center",
									marginBottom: "8px",
									fontSize: ".9rem",
								}}
								variant="h6"
								component="p"
							>
								{file.name}
							</Typography>
							<Typography
								style={{
									fontSize: ".8rem",
									marginBottom: "16px",
									color: "white",
								}}
							>
								Size: {formatFileSize(file.size)}
							</Typography>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									marginTop: "auto",
								}}
							>
								{file.isPersonal && (
									<Button
										variant="contained"
										color="primary"
										onClick={() => handleShareFile(file)}
										style={{ marginRight: "8px" }}
									>
										<FontAwesomeIcon
											icon={faShareAlt}
											style={{ marginRight: "5px" }}
										/>{" "}
										Share
									</Button>
								)}
								{file.uploadedBy === user.uid && (
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => handleDeleteFile(file)}
									>
										<FontAwesomeIcon
											icon={faTrashAlt}
											style={{ marginRight: "5px" }}
										/>{" "}
										Delete
									</Button>
								)}
							</div>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);

	const renderListView = () => (
		<ul className="file-list-view">
			{filteredFiles.map((file) => (
				<li key={file.id} className={`list-item-${switchChecked}`}>
					<div className="file-icon">
						<FontAwesomeIcon
							icon={getFileIcon(file.name)}
							style={{ color: getFileColor(file) }}
						/>
					</div>
					<div className="file-details">
						<span className="file-name" onClick={() => handleNameClick(file)}>
							{file.name}
						</span>
						<span className="file-size">{formatFileSize(file.size)}</span>
						<div className="file-actions">
							{file.isPersonal && (
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => handleShareFile(file)}
									style={{ marginRight: "8px" }}
								>
									<FontAwesomeIcon icon={faShareAlt} /> Share
								</Button>
							)}
							{file.uploadedBy === user.uid && (
								<Button
									variant="outlined"
									color="secondary"
									size="small"
									onClick={() => handleDeleteFile(file)}
								>
									<FontAwesomeIcon icon={faTrashAlt} /> Delete
								</Button>
							)}
						</div>
					</div>
				</li>
			))}
		</ul>
	);

	const renderColumnView = () => (
		<div className="column-view">
			{filteredFiles.map((file) => (
				<div key={file.id} className={`column-item-${switchChecked}`}>
					<FontAwesomeIcon
						icon={getFileIcon(file.name)}
						style={{
							color: getFileColor(file),
							marginRight: "10px",
						}}
					/>
					<span className="file-name" onClick={() => handleNameClick(file)}>
						{file.name}
					</span>
					<span className="file-size">{formatFileSize(file.size)}</span>
					<div className="file-actions">
						{file.isPersonal && (
							<Button
								variant="contained"
								color="primary"
								size="small"
								onClick={() => handleShareFile(file)}
								style={{ marginRight: "8px" }}
							>
								<FontAwesomeIcon icon={faShareAlt} />
							</Button>
						)}
						{file.uploadedBy === user.uid && (
							<Button
								variant="outlined"
								color="secondary"
								size="small"
								onClick={() => handleDeleteFile(file)}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</Button>
						)}
					</div>
				</div>
			))}
		</div>
	);

	if (loading) {
		return (
			<div
				className={`main-content template-content main-color-${switchChecked}`}
			>
				Loading...
			</div>
		);
	}

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<h2>My Knowledge Center</h2>
			<div className="knowledge-content">
				<div className="search-filter-container">
					<div className="search-section" style={{ marginRight: "35px" }}>
						<input
							type="text"
							placeholder="Search files..."
							className="search-files"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
					<FormControl
						variant="outlined"
						style={{ minWidth: 120 }}
						className="viewMode"
					>
						<InputLabel>View Mode</InputLabel>
						<Select
							value={viewMode}
							onChange={(e) => setViewMode(e.target.value)}
							label="View Mode"
						>
							<MenuItem value="cards">Cards</MenuItem>
							<MenuItem value="list">Columns</MenuItem>
							<MenuItem value="columns">List</MenuItem>
						</Select>
					</FormControl>
				</div>

				<div className="file-list">
					{viewMode === "cards" && renderCardView()}
					{viewMode === "list" && renderListView()}
					{viewMode === "columns" && renderColumnView()}
				</div>
			</div>

			{isShareModalOpen && (
				<div className="modal">
					<div className="modal-content">
						<h3>Share with Team Members or Departments</h3>
						<h4>Team Members</h4>
						{teamMembers.map((member) => (
							<div key={member.id}>
								<input
									type="checkbox"
									id={`member-${member.id}`}
									checked={selectedMembers.some((m) => m.id === member.id)}
									onChange={() => {
										setSelectedMembers((prev) =>
											prev.some((m) => m.id === member.id)
												? prev.filter((m) => m.id !== member.id)
												: [...prev, member]
										);
									}}
								/>
								<label htmlFor={`member-${member.id}`}>{member.email}</label>
							</div>
						))}
						<h4>Departments</h4>
						{departments.map((department) => (
							<div key={department.id}>
								<input
									type="checkbox"
									id={`department-${department.id}`}
									checked={selectedDepartments.some(
										(d) => d.id === department.id
									)}
									onChange={() => {
										setSelectedDepartments((prev) =>
											prev.some((d) => d.id === department.id)
												? prev.filter((d) => d.id !== department.id)
												: [...prev, department]
										);
									}}
								/>
								<label htmlFor={`department-${department.id}`}>
									{department.name}
								</label>
							</div>
						))}
						<div className="modal-buttons">
							<button
								onClick={() => setIsShareModalOpen(false)}
								className="knowledgebase-share-btn"
							>
								Cancel
							</button>
							<button
								onClick={handleShareConfirm}
								className="knowledgebase-share-btn"
							>
								Share
							</button>
						</div>
					</div>
				</div>
			)}
			{isPopupOpen && (
				<div className="popup-overlay">
					<button
						onClick={() => setShowSharedWithSection(!showSharedWithSection)}
						className="knowledgebase-share-btn"
					>
						{showSharedWithSection ? "Preview" : "Show Shared With"}
					</button>
					{showSharedWithSection ? (
						<div className="shared-with-section">
							<h3>
								<FontAwesomeIcon icon={faUsers} /> Shared with:
							</h3>
							<h4 className="sharedWithTitles">Users:</h4>
							{currentFileSharedWith.length > 0 ? (
								currentFileSharedWith.map((email, index) => (
									<p key={index}>{email}</p>
								))
							) : (
								<p>This file is not shared with any individual users.</p>
							)}
							<h4 className="sharedWithTitles">Departments:</h4>
							{currentFile &&
							currentFile.sharedDepartments &&
							currentFile.sharedDepartments.length > 0 ? (
								currentFile.sharedDepartments.map((deptId) => {
									const department = departments.find((d) => d.id === deptId);
									return (
										<p key={deptId}>
											{department ? department.name : "Unknown Department"}
										</p>
									);
								})
							) : (
								<p>This file is not shared with any departments.</p>
							)}
						</div>
					) : (
						<div className="popup-content">
							<button className="close-button" onClick={closePopup}>
								<FontAwesomeIcon icon={faTimes} />
							</button>
							{popupType === "text" && isEditing && (
								<div>
									<textarea
										value={editContent}
										onChange={(e) => setEditContent(e.target.value)}
										rows="20"
										cols="80"
										className="file-viewer"
									></textarea>
									<button
										onClick={handleSave}
										className="knowledgebase-share-btn"
									>
										<FontAwesomeIcon icon={faFloppyDisk} /> Save
									</button>
								</div>
							)}
							{popupType === "image" && (
								<img src={popupContent} alt="Preview" width="100%" />
							)}
							{popupType === "pdf" && (
								<iframe
									src={popupContent}
									title="PDF Preview"
									width="100%"
									height="100%"
								></iframe>
							)}
							{popupType === "other" && (
								<p>Preview not available for this file type.</p>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default AllKnowledgeBase;
