// ScraperFlow.js
import axios from "axios";

export const scrapeUrl = async (url) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/scrapeAndStore`,
            { url },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        const scrapedData = response.data; // This is now an array of results
        await new Promise((resolve) => setTimeout(resolve, 3000));

        const scrapedText = scrapedData
            .map((item) => `URL: ${item.url}\nTEXT: ${item.text}`)
            .join("\n\n");
        return scrapedText;
    } catch (error) {
        console.error("Error scraping the URL:", error);
        throw error;
    }
};
