import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { db } from "../firebaseConfig";
import {
	collection,
	doc,
	setDoc,
	getDoc,
	updateDoc,
	getDocs,
	query,
	where,
} from "firebase/firestore";
import "../Styles/AccountTypeSelection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStripe } from "@fortawesome/free-brands-svg-icons";

const stripePromise = loadStripe(
	"pk_test_51PXl42HauYUkGvLIWrz4gnqR7oracH3obc9hMDRBJSAXicLJh3kA4aSmg3lNNBpWULW90TZxBFVBm3yYcygLACF900lWxt1JxG"
);

const appearance = {
	theme: "night",
	labels: "floating",
};

const AccountTypeSelection = ({ user, onClose, switchChecked }) => {
	const [emails, setEmails] = useState(["", "", "", "", ""]);
	const [accountType, setAccountType] = useState("");
	const [showCheckout, setShowCheckout] = useState(false);
	// eslint-disable-next-line
	const [priceId, setPriceId] = useState("");
	// eslint-disable-next-line
	const [submitClicked, setSubmitClicked] = useState(false);
	const [transactionSuccess, setTransactionSuccess] = useState(false);
	const [clientSecret, setClientSecret] = useState("");
	const [showEmailInputs, setShowEmailInputs] = useState(false);
	const [sendingEmail, setSendingEmail] = useState(false);
	const [isEligibleForFreeTrial, setIsEligibleForFreeTrial] = useState(true);
	const navigate = useNavigate();

	const plans = [
		{
			name: "FREE TRIAL",
			price: "$0",
			tokens: 10000,
			description: "Experience SpinFlow Risk-Free for 7 days",
		},
		{
			name: "PERSONAL",
			price: "$199",
			tokens: 20000,
			description:
				"Best for: Individuals just getting started with AI automation",
		},
		{
			name: "TEAM",
			price: "$399",
			tokens: 40000,
			description: "Best for: Growing businesses with AI automation needs",
		},
	];

	useEffect(() => {
		checkFreeTrialEligibility();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (transactionSuccess || accountType === "FREE TRIAL") {
			setShowEmailInputs(true);
		}
	}, [transactionSuccess, accountType]);

	const checkFreeTrialEligibility = async () => {
		try {
			const userDoc = await getDoc(doc(db, "users", user.uid));
			const userData = userDoc.data();

			if (userData.hasHadFreeTrial) {
				setIsEligibleForFreeTrial(false);
				return;
			}

			if (userData.teamId) {
				const teamDoc = await getDoc(doc(db, "teams", userData.teamId));
				const teamData = teamDoc.data();

				if (teamData.hasHadFreeTrial) {
					setIsEligibleForFreeTrial(false);
					return;
				}

				const teamMembersQuery = query(
					collection(db, "users"),
					where("teamId", "==", userData.teamId)
				);
				const teamMembersSnapshot = await getDocs(teamMembersQuery);
				for (const memberDoc of teamMembersSnapshot.docs) {
					if (memberDoc.data().hasHadFreeTrial) {
						setIsEligibleForFreeTrial(false);
						return;
					}
				}
			}

			setIsEligibleForFreeTrial(true);
		} catch (error) {
			console.error("Error checking free trial eligibility:", error);
			setIsEligibleForFreeTrial(false);
		}
	};

	const handleAccountTypeSelection = async (type) => {
		setAccountType(type);
		if (type === "FREE TRIAL") {
			if (isEligibleForFreeTrial) {
				await startFreeTrial();
			} else {
				alert(
					"You or a team member have already used the free trial. Please select a paid plan."
				);
			}
		} else {
			setPriceId(getPriceId(type));
			await handleSubmit(type);
		}
	};

	const startFreeTrial = async () => {
		try {
			const trialEndDate = new Date();
			trialEndDate.setDate(trialEndDate.getDate() + 7);

			const userDoc = await getDoc(doc(db, "users", user.uid));
			let teamId = userDoc.data().teamId;

			if (!teamId) {
				teamId = doc(collection(db, "teams")).id;
				await setDoc(doc(db, "teams", teamId), {
					teamID: teamId,
					tokens: 10000,
					tokenCount: 0,
					members: [user.email],
					creator: user.email,
					trialEndDate: trialEndDate,
					accountType: "FREE TRIAL",
					hasHadFreeTrial: true,
				});
			} else {
				await updateDoc(doc(db, "teams", teamId), {
					tokens: 10000,
					tokenCount: 0,
					trialEndDate: trialEndDate,
					accountType: "FREE TRIAL",
					hasHadFreeTrial: true,
				});
			}

			await updateDoc(doc(db, "users", user.uid), {
				accountType: "FREE TRIAL",
				teamId: teamId,
				levelType: "admin",
				hasHadFreeTrial: true,
			});

			setShowEmailInputs(true);
		} catch (error) {
			console.error("Error starting free trial:", error);
			alert(
				"An error occurred while starting the free trial. Please try again."
			);
		}
	};

	const handleEmailChange = (index, value) => {
		const newEmails = [...emails];
		newEmails[index] = value;
		setEmails(newEmails);
	};

	const handleSubmit = async (type) => {
		setSubmitClicked(true);

		try {
			const response = await fetch(
				"https://us-central1-spinflow-13626.cloudfunctions.net/createPaymentIntent",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						priceId: getPriceId(type),
						userId: user.uid,
						accountType: type,
						email: user.email,
					}),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(`Error: ${errorData.error}`);
			}

			const { client_secret, subscription_id } = await response.json();
			setClientSecret(client_secret);

			await updateDoc(doc(db, "users", user.uid), {
				subscriptionId: subscription_id,
			});

			setShowCheckout(true);
		} catch (error) {
			console.error("Error during payment intent creation:", error);
			alert(`An error occurred: ${error.message}. Please try again.`);
			setSubmitClicked(false);
		}
	};

	const handleInviteSubmit = async () => {
		if (!emails.some((email) => email.trim() !== "")) {
			alert("Please enter at least one email address.");
			return;
		}
		setSendingEmail(true);

		try {
			const userDoc = await getDoc(doc(db, "users", user.uid));
			const teamId = userDoc.data().teamId;

			if (!teamId) {
				console.error("Team ID not found");
				return;
			}

			const validEmails = emails.filter((email) => email.trim() !== "");

			await updateDoc(doc(db, "teams", teamId), {
				members: [...new Set([user.email, ...validEmails])],
			});

			await updateDoc(doc(db, "users", user.uid), {
				teamMembers: validEmails,
			});

			if (validEmails.length > 0) {
				await fetch(
					"https://us-central1-spinflow-13626.cloudfunctions.net/sendTeamInvites",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							emails: validEmails,
							inviter: user.email,
							teamId,
						}),
					}
				);
			}

			alert("Team updated successfully!");
			onClose();
			setSendingEmail(false);
			navigate("/");
		} catch (firestoreError) {
			console.error("Error updating team: ", firestoreError);
			alert("Error updating team. Please try again.");
		}
	};

	const getPriceId = (accountType) => {
		switch (accountType) {
			case "PERSONAL":
				return "price_1PalvTHauYUkGvLIORhHDz85";
			case "TEAM":
				return "price_1PalwEHauYUkGvLIJ1WpDHsB";
			default:
				return null;
		}
	};

	const CheckoutForm = ({
		clientSecret,
		userId,
		accountType,
		setTransactionSuccess,
	}) => {
		const stripe = useStripe();
		const elements = useElements();
		const [error, setError] = useState(null);
		const [loading, setLoading] = useState(false);

		const handleSubmit = async (event) => {
			event.preventDefault();
			setLoading(true);

			if (!stripe || !elements) {
				console.log("Stripe or elements is not initialized");
				setLoading(false);
				return;
			}

			try {
				console.log("Confirming payment...");
				const { error: stripeError, paymentIntent } =
					await stripe.confirmPayment({
						elements,
						confirmParams: {},
						redirect: "if_required",
					});

				if (stripeError) {
					console.error("Stripe error:", stripeError);
					setError(stripeError.message);
					setLoading(false);
					return;
				}

				if (paymentIntent.status !== "succeeded") {
					console.error("Payment failed:", paymentIntent);
					setError("Payment failed. Please try again.");
					setLoading(false);
					return;
				}

				console.log("Payment confirmed, updating database...");
				let purchasedTokens = accountType === "PERSONAL" ? 20000 : 40000;

				if (!db) {
					console.error("Firestore database instance is undefined");
					setError("Database error. Please try again.");
					setLoading(false);
					return;
				}

				if (!userId) {
					console.error("User ID is undefined");
					setError("User ID not found. Please try again.");
					setLoading(false);
					return;
				}

				console.log("Fetching user document...");
				const userDocRef = doc(db, "users", userId);
				const userDoc = await getDoc(userDocRef);

				if (!userDoc.exists()) {
					console.error("User document not found");
					setError("User data not found. Please try again.");
					setLoading(false);
					return;
				}

				let teamId = userDoc.data().teamId;

				if (!teamId) {
					console.log("Team ID not found, creating new team...");
					const newTeamRef = doc(collection(db, "teams"));
					teamId = newTeamRef.id;
					await setDoc(newTeamRef, {
						teamID: teamId,
						tokens: purchasedTokens,
						tokenCount: 0,
						members: [userDoc.data().email],
						creator: userDoc.data().email,
						accountType: accountType,
					});
					console.log("New team created with ID:", teamId);
				}

				console.log("Updating team document...");
				const teamDocRef = doc(db, "teams", teamId);
				await updateDoc(teamDocRef, {
					accountType,
					tokens: purchasedTokens,
					tokenCount: 0,
				});

				console.log("Updating user document...");
				await updateDoc(userDocRef, {
					accountType,
					levelType: "admin",
					teamId: teamId,
				});

				setTransactionSuccess(true);
				setLoading(false);
				alert("Payment successful!");
			} catch (error) {
				console.error("Error during payment confirmation:", error);
				setError(error.message || "An unexpected error occurred");
				setLoading(false);
			}
		};

		return (
			<form onSubmit={handleSubmit}>
				<PaymentElement />
				{error && <div className="error">{error}</div>}
				<button
					type="submit"
					className="submit-button"
					disabled={!stripe || loading}
				>
					{loading ? "Processing..." : "Pay"}
				</button>
			</form>
		);
	};

	return (
		<div className={`account-type-selection main-color-${switchChecked}`}>
			{!showCheckout && !showEmailInputs && (
				<>
					<h2>Select Your Account Type</h2>
					<div className="card-container">
						{plans.map((plan) => (
							<div
								key={plan.name}
								onClick={() => handleAccountTypeSelection(plan.name)}
								className={`card ${
									accountType === plan.name ? "selected" : ""
								} card-color-${switchChecked} ${
									plan.name === "FREE TRIAL" && !isEligibleForFreeTrial
										? "disabled"
										: ""
								}`}
								style={{
									display: `${
										plan.name === "FREE TRIAL" &&
										!isEligibleForFreeTrial &&
										"none"
									}`,
								}}
							>
								<h2>
									{plan.name === "PERSONAL"
										? "Basic Plan"
										: plan.name === "TEAM"
										? "Pro Plan"
										: "Free Trial"}
								</h2>
								<h4 className="card-info-h4">{plan.price} /Month</h4>
								<h4 className="card-info-h4">{plan.tokens} Tokens</h4>
								<h5>{plan.description}</h5>
								{plan.name === "FREE TRIAL" && !isEligibleForFreeTrial && (
									<p className="not-eligible">Not eligible for free trial</p>
								)}
							</div>
						))}
						<div className={`card card-color-${switchChecked}`}>
							<h2>Enterprise</h2>
							<h5>Reach out to our team</h5>
						</div>
					</div>
				</>
			)}
			{showCheckout && clientSecret && !transactionSuccess && (
				<Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
					<h3 className="poweredBy">
						Secured By{" "}
						<FontAwesomeIcon
							icon={faStripe}
							size="2x"
							className="stripe-icon"
						/>
					</h3>
					<h4>{accountType} account selected</h4>
					<CheckoutForm
						clientSecret={clientSecret}
						userId={user.uid}
						accountType={accountType}
						setTransactionSuccess={setTransactionSuccess}
					/>
				</Elements>
			)}
			{(showEmailInputs || accountType === "FREE TRIAL") && (
				<div className="team-emails">
					<h3>Enter Team Member Emails (up to 5)</h3>
					{emails.map((email, index) => (
						<input
							className="template-name-input"
							key={index}
							type="email"
							value={email}
							onChange={(e) => handleEmailChange(index, e.target.value)}
							placeholder={`Email ${index + 1}`}
						/>
					))}
					{sendingEmail ? (
						<button className="submit-button">Updating Team...</button>
					) : (
						<>
							<button onClick={handleInviteSubmit} className="submit-button">
								Update Team
							</button>
							<button
								onClick={() => {
									onClose();
									setSendingEmail(false);
									navigate("/");
								}}
								className="submit-button"
							>
								Complete Later
							</button>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default AccountTypeSelection;
