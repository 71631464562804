const ProKnowledgeBase = ({ user, switchChecked }) => {
    return (
        <div className={`main-content main-color-${switchChecked}`}>
            <h2>PRO Knowledge Base</h2>
            <div className="knowledge-content">
                <div className="search-section">
                    {/* <input
                        type="text"
                        placeholder="Search files..."
                        className="search-files"
                        value=""
                    /> */}
                </div>
                <div className="file-list">

                </div>
            </div>

        </div>
    );
};

export default ProKnowledgeBase;