import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Credentials = ({ user, switchChecked }) => {
	const [apiKey, setApiKey] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const [teamId, setTeamId] = useState(null);

	useEffect(() => {
		const checkAdminStatusAndFetchTeamId = async () => {
			if (!user || !user.uid) return;

			const userDoc = await getDoc(doc(db, "users", user.uid));
			if (userDoc.exists()) {
				const userData = userDoc.data();
				setIsAdmin(userData.levelType === "admin");
				setTeamId(userData.teamId);
			}
		};

		checkAdminStatusAndFetchTeamId();
	}, [user]);

	useEffect(() => {
		const fetchApiKey = async () => {
			if (!teamId) return;

			try {
				const teamDoc = await getDoc(doc(db, "teams", teamId));
				if (teamDoc.exists()) {
					setApiKey(teamDoc.data().zapierApiKey || "");
				}
			} catch (error) {
				console.error("Error fetching API key:", error);
			}
		};

		fetchApiKey();
	}, [teamId]);

	const handleSaveApiKey = async () => {
		if (!teamId) {
			alert("Unable to save API key. Team ID is not available.");
			return;
		}

		try {
			await setDoc(
				doc(db, "teams", teamId),
				{ zapierApiKey: apiKey },
				{ merge: true }
			);
			alert("Zapier API Key saved successfully!");
		} catch (error) {
			console.error("Error saving API key:", error);
			alert("Failed to save API key. Please try again.");
		}
	};

	if (!isAdmin) return null;

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<h3>Zapier API Key Management</h3>
			<input
				type="text"
				value={apiKey}
				onChange={(e) => setApiKey(e.target.value)}
				placeholder="Enter Zapier API Key"
			/>
			<button onClick={handleSaveApiKey} className="main-content-button">
				Save API Key
			</button>
		</div>
	);
};

export default Credentials;
