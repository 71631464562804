import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
    doc,
    setDoc,
    getDoc,
    getDocs,
    onSnapshot,
    updateDoc,
    arrayUnion,
    serverTimestamp,
    collection,
    query,
    where,
} from "firebase/firestore";
import "../Styles/MessagingPage.css";

const MessagingPage = ({ user, teamId, switchChecked }) => {
    const [conversations, setConversations] = useState({});
    const [newMessage, setNewMessage] = useState("");
    const [activeChat, setActiveChat] = useState(null);
    const [newChatEmail, setNewChatEmail] = useState("");
    const [teamMembers, setTeamMembers] = useState([]);
    const [emailSuggestions, setEmailSuggestions] = useState([]);

    useEffect(() => {
        if (!teamId || !user.email) return;

        const fetchTeamMembers = async () => {
            const teamDoc = await getDoc(doc(db, "teams", teamId));
            if (teamDoc.exists()) {
                const data = teamDoc.data();
                setTeamMembers(data.members || []);
            }
        };

        const fetchConversations = async () => {
            const chatsRef = collection(db, "teams", teamId, "chats");
            const q = query(
                chatsRef,
                where("participants", "array-contains", user.email)
            );

            const querySnapshot = await getDocs(q);
            const fetchedConversations = {};

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const otherParticipant = data.participants.find(
                    (p) => p !== user.email
                );
                fetchedConversations[otherParticipant] = data.messages || [];
            });

            setConversations(fetchedConversations);
        };

        fetchTeamMembers();
        fetchConversations();
    }, [teamId, user.email]);

    useEffect(() => {
        if (!teamId || !user.email) return;

        const unsubscribePromises = Object.keys(conversations).map(
            (recipientEmail) => {
                const chatId = getChatId(user.email, recipientEmail);
                const chatRef = doc(db, "teams", teamId, "chats", chatId);

                return onSnapshot(chatRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const data = docSnapshot.data();
                        setConversations((prev) => ({
                            ...prev,
                            [recipientEmail]: data.messages || [],
                        }));
                    }
                });
            }
        );

        return () => {
            unsubscribePromises.forEach((unsubscribe) => unsubscribe());
        };
    }, [teamId, user.email, conversations]);

    const getChatId = (email1, email2) => {
        return [email1, email2].sort().join("_");
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !activeChat) {
            console.log("Cannot send message: ", { newMessage, activeChat });
            return;
        }

        const chatId = getChatId(user.email, activeChat);
        const chatRef = doc(db, "teams", teamId, "chats", chatId);

        const newMessageObj = {
            text: newMessage,
            senderEmail: user.email,
            timestamp: new Date().toISOString(),
        };

        try {
            const docSnap = await getDoc(chatRef);

            if (docSnap.exists()) {
                await updateDoc(chatRef, {
                    messages: arrayUnion(newMessageObj),
                    lastUpdated: serverTimestamp(),
                });
            } else {
                await setDoc(chatRef, {
                    participants: [user.email, activeChat],
                    messages: [newMessageObj],
                    lastUpdated: serverTimestamp(),
                });
            }

            console.log("Message sent successfully");
            setNewMessage("");
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const startNewChat = async (e) => {
        e.preventDefault();
        if (!newChatEmail.trim()) return;

        const recipientEmail = newChatEmail.trim();
        if (conversations[recipientEmail]) {
            setActiveChat(recipientEmail);
            setNewChatEmail("");
            return;
        }

        const chatId = getChatId(user.email, recipientEmail);
        const chatRef = doc(db, "teams", teamId, "chats", chatId);

        try {
            await setDoc(
                chatRef,
                {
                    participants: [user.email, recipientEmail],
                    messages: [],
                    lastUpdated: serverTimestamp(),
                },
                { merge: true }
            );

            setConversations((prev) => ({ ...prev, [recipientEmail]: [] }));
            setActiveChat(recipientEmail);
            setNewChatEmail("");
            console.log("New chat started with:", recipientEmail);
        } catch (error) {
            console.error("Error starting new chat:", error);
        }
    };

    const handleNewChatEmailChange = (e) => {
        const input = e.target.value;
        setNewChatEmail(input);

        if (input.trim() === "") {
            setEmailSuggestions([]);
        } else {
            const suggestions = teamMembers.filter((email) =>
                email.toLowerCase().includes(input.toLowerCase())
            );
            setEmailSuggestions(suggestions);
        }
    };

    const selectEmailSuggestion = (email) => {
        setNewChatEmail(email);
        setEmailSuggestions([]);
    };

    return (
        <div
            className={`main-content main-color-${switchChecked} chat-interface-main`}
        >
            <div className="chat-sidebar">
                <div className={`chat-list card-color-${switchChecked}`}>
                    {Object.keys(conversations).map((email, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveChat(email)}
                            className={`chat-button ${
                                activeChat === email ? "active" : ""
                            }`}
                        >
                            {email}
                        </button>
                    ))}
                </div>
                <form onSubmit={startNewChat} className="new-sms">
                    <div className="email-input-container">
                        <input
                            type="email"
                            value={newChatEmail}
                            onChange={handleNewChatEmailChange}
                            placeholder="New chat email"
                        />
                        {emailSuggestions.length > 0 && (
                            <ul
                                className={`email-suggestions card-color-${switchChecked}`}
                            >
                                {emailSuggestions.map((email, index) => (
                                    <li
                                        key={index}
                                        onClick={() =>
                                            selectEmailSuggestion(email)
                                        }
                                    >
                                        {email}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <button type="submit">Start Chat</button>
                </form>
            </div>
            <div className={`chat-interface card-color-${switchChecked}`}>
                <div className="chat-header">{activeChat}</div>
                <div className={`messages card-color-${switchChecked}`}>
                    {activeChat &&
                        conversations[activeChat]?.map((msg, index) => (
                            <div
                                key={index}
                                className={`message-bubble ${
                                    msg.senderEmail === user.email
                                        ? "sent"
                                        : "received"
                                }`}
                            >
                                <span className="message-text">{msg.text}</span>
                                <span className="message-time">
                                    {new Date(msg.timestamp).toLocaleTimeString(
                                        [],
                                        { hour: "2-digit", minute: "2-digit" }
                                    )}
                                </span>
                            </div>
                        ))}
                </div>
                <form
                    onSubmit={sendMessage}
                    className={`message-form card-color-${switchChecked}`}
                >
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type a message..."
                        required
                        className="chat-textarea"
                    />
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    );
};

export default MessagingPage;
