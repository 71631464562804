import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	addDoc,
	getDocs,
	doc,
	updateDoc,
	deleteDoc,
	getDoc,
	where,
	query,
} from "firebase/firestore";
import "./styles/MainDepartments.css";

const colorOptions = [
	{ name: "Bright Pink", value: "#FF3366" },
	{ name: "Mint Green", value: "#33CC99" },
	{ name: "Azure Blue", value: "#3366FF" },
	{ name: "Golden Yellow", value: "#FFCC00" },
	{ name: "Vivid Orange", value: "#FF6600" },
	{ name: "Electric Purple", value: "#9933FF" },
	{ name: "Bright Sky Blue", value: "#00CCFF" },
	{ name: "Amber", value: "#FF9900" },
	{ name: "Lime Green", value: "#66CC00" },
	{ name: "Hot Pink", value: "#FF3399" },
	{ name: "Electric Indigo", value: "#6600FF" },
	{ name: "Spring Green", value: "#00FF99" },
	{ name: "Coral", value: "#FF6633" },
	{ name: "Dodger Blue", value: "#3399FF" },
	{ name: "Lemon Yellow", value: "#FFFF33" },
	{ name: "Heliotrope", value: "#CC33FF" },
	{ name: "Turquoise", value: "#33FFCC" },
	{ name: "Red Orange", value: "#FF5733" },
	{ name: "Material Green", value: "#4CAF50" },
	{ name: "Material Light Blue", value: "#03A9F4" },
];

const ColorDropdown = ({ value, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSelect = (color) => {
		onChange(color);
		setIsOpen(false);
	};

	return (
		<div className="color-dropdown" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="color-dropdown-toggle"
			>
				{value ? (
					<>
						<span
							className="color-sample"
							style={{ backgroundColor: value }}
						></span>
						{colorOptions.find((c) => c.value === value)?.name}
					</>
				) : (
					"Select a color"
				)}
			</button>
			{isOpen && (
				<ul className="color-dropdown-menu">
					{colorOptions.map((color) => (
						<li key={color.value} onClick={() => handleSelect(color.value)}>
							<span
								className="color-sample"
								style={{ backgroundColor: color.value }}
							></span>
							{color.name}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

const MainDepartments = ({ user, switchChecked }) => {
	const [departments, setDepartments] = useState([]);
	const [newDepartmentName, setNewDepartmentName] = useState("");
	const [newDepartmentColor, setNewDepartmentColor] = useState(null);
	const [teamId, setTeamId] = useState("");

	useEffect(() => {
		const fetchUserData = async () => {
			const userDoc = await getDoc(doc(db, `users/${user.uid}`));
			if (userDoc.exists()) {
				setTeamId(userDoc.data().teamId);
			}
		};

		fetchUserData();
	}, [user.uid]);

	useEffect(() => {
		const fetchDepartmentsAndStats = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsData = departmentsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				const updatedDepartments = await Promise.all(
					departmentsData.map(async (dept) => {
						const stats = await getDepartmentStats(dept.id);
						return {
							...dept,
							...stats,
						};
					})
				);

				setDepartments(updatedDepartments);
			}
		};

		if (teamId) {
			fetchDepartmentsAndStats();
		}
		// eslint-disable-next-line
	}, [teamId]);

	const getDepartmentStats = async (departmentId) => {
		const collections = ["knowledgebase", "assistants", "flows"];
		let knowledgeBases = 0;
		let agents = 0;
		let flows = 0;

		for (const collectionName of collections) {
			const q = query(
				collection(db, `teams/${teamId}/${collectionName}`),
				where("sharedDepartments", "array-contains", departmentId)
			);
			const snapshot = await getDocs(q);

			const q2 = query(
				collection(db, `teams/${teamId}/${collectionName}`),
				where("departments", "array-contains", departmentId)
			);
			const snapshot2 = await getDocs(q2);

			if (collectionName === "knowledgebase") {
				knowledgeBases = snapshot.size;
			} else if (collectionName === "assistants") {
				agents = snapshot.size ? snapshot.size : snapshot2.size;
			} else if (collectionName === "flows") {
				flows = snapshot.size ? snapshot.size : snapshot2.size;
			}
		}

		return { knowledgeBases, agents, flows };
	};

	const handleAddDepartment = async () => {
		if (newDepartmentName.trim() !== "" && newDepartmentColor) {
			const newDepartment = {
				name: newDepartmentName,
				color: newDepartmentColor,
			};
			const docRef = await addDoc(
				collection(db, `teams/${teamId}/departments`),
				newDepartment
			);
			setDepartments([
				...departments,
				{
					id: docRef.id,
					...newDepartment,
					knowledgeBases: 0,
					agents: 0,
					flows: 0,
				},
			]);
			setNewDepartmentName("");
			setNewDepartmentColor(null);

			// Update the team document to include the new department
			const teamRef = doc(db, "teams", teamId);
			await updateDoc(teamRef, {
				departments: [...departments.map((d) => d.id), docRef.id],
			});
		}
	};

	const handleDeleteDepartment = async (departmentId) => {
		await deleteDoc(doc(db, `teams/${teamId}/departments`, departmentId));
		setDepartments(departments.filter((dept) => dept.id !== departmentId));

		// Update the team document to remove the deleted department
		const teamRef = doc(db, "teams", teamId);
		await updateDoc(teamRef, {
			departments: departments
				.filter((d) => d.id !== departmentId)
				.map((d) => d.id),
		});
	};

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<h2>Department Management</h2>
			<div className="input-group">
				<input
					type="text"
					value={newDepartmentName}
					onChange={(e) => setNewDepartmentName(e.target.value)}
					placeholder="New Department Name"
				/>
				<ColorDropdown
					value={newDepartmentColor}
					onChange={setNewDepartmentColor}
				/>
				<button
					onClick={handleAddDepartment}
					disabled={!newDepartmentColor || newDepartmentName.trim() === ""}
				>
					Add Department
				</button>
			</div>
			<div className="department-list">
				{departments.map((dept) => (
					<div
						key={dept.id}
						className="department-item"
						style={{
							borderLeft: `10px solid ${dept.color}`,
							borderRight: `10px solid ${dept.color}`,
						}}
					>
						<span className="department-name">{dept.name}</span>
						<span className="department-stats">
							{dept.knowledgeBases} Knowledgebases
						</span>
						<span>{dept.agents} Agents</span>
						<span>{dept.flows} Flows</span>
						<span>{dept.knowledgeBases + dept.agents + dept.flows} Total</span>
						<button
							onClick={() => handleDeleteDepartment(dept.id)}
							className="delete-button"
						>
							Delete
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default MainDepartments;
