import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Styles/WorkflowList.css";

// Template Configs
import {
	prompts as marketingPrompts,
	templateName as marketingTemplateName,
} from "./FlowsData/MarketingStrategyFlow";

import {
	prompts as salesPrompts,
	templateName as salesTemplateName,
} from "./FlowsData/SalesLeadQualificationFlow";

import {
	prompts as productLaunchPrompt,
	templateName as productLaunchTemplateName,
} from "./FlowsData/ProductLaunchWorkflow";

import {
	prompts as leadsPrompts,
	templateName as leadsTemplateName,
} from "./FlowsData/LeadNurturingAutomationFlow";

import {
	prompts as PostLaunchPrompts,
	templateName as PostLaunchTemplateName,
} from "./FlowsData/PostLaunchPerformanceAnalystFlows";

import {
	prompts as SaleForecastingPrompts,
	templateName as SalesForecastingTemplateName,
} from "./FlowsData/SalesForecastingAndStrategyAgentFlow";

import {
	prompts as CompanyLaunchWorkflowPrompts,
	templateName as CompanyLaunchWorkflowTemplateName,
} from "./FlowsData/CompanyLaunchWorkflowFlow";

const ProFlows = ({ user, switchChecked }) => {
	// eslint-disable-next-line
	const [workflows, setWorkflows] = useState([]);
	const [templates, setTemplates] = useState([]);
	// eslint-disable-next-line
	const [teamId, setTeamId] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		const loadWorkflows = async () => {
			try {
				// First, get the user's team ID
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);

				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					const teamId = userData.teamId;
					setTeamId(teamId);

					if (teamId) {
						const querySnapshot = await getDocs(
							collection(db, `teams/${teamId}/flows`)
						);
						const loadedWorkflows = [];
						querySnapshot.forEach((doc) => {
							loadedWorkflows.push({ id: doc.id, ...doc.data() });
						});
						setWorkflows(loadedWorkflows);
					} else {
						console.error("User is not part of a team");
					}
				} else {
					console.error("User document not found");
				}
			} catch (error) {
				console.error("Error loading workflows: ", error);
			}
		};

		// Load templates from predefined configurations
		const loadTemplates = () => {
			const loadedTemplates = [
				{
					id: "template-1",
					templateName: marketingTemplateName,
					prompts: marketingPrompts,
				},
				{
					id: "template-2",
					templateName: salesTemplateName,
					prompts: salesPrompts,
				},
				{
					id: "template-3",
					templateName: productLaunchTemplateName,
					prompts: productLaunchPrompt,
				},
				{
					id: "template-4",
					templateName: leadsTemplateName,
					prompts: leadsPrompts,
				},
				{
					id: "template-5",
					templateName: PostLaunchTemplateName,
					prompts: PostLaunchPrompts,
				},
				{
					id: "template-6",
					templateName: SalesForecastingTemplateName,
					prompts: SaleForecastingPrompts,
				},
				{
					id: "template-7",
					templateName: CompanyLaunchWorkflowTemplateName,
					prompts: CompanyLaunchWorkflowPrompts,
				},
			];
			setTemplates(loadedTemplates);
		};

		loadWorkflows();
		loadTemplates();
	}, [user.uid]);

	const handleCreateFromTemplate = async (template) => {
		try {
			if (!teamId) {
				console.error("User is not part of a team");
				return;
			}
			const newWorkflowRef = await addDoc(
				collection(db, `teams/${teamId}/flows`),
				{
					prompts: template.prompts,
					title: template.templateName,
					createdBy: user.uid,
					createdAt: new Date(),
					templateName: template.templateName,
					isPersonal: true,
					departments: "",
				}
			);
			navigate(`/users/${user.uid}/workflows/${newWorkflowRef.id}`);
		} catch (error) {
			console.error("Error creating new workflow from template: ", error);
		}
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<h2>Pro Flows</h2>
			<div className={`template-grid template-color-${switchChecked}`}>
				{/* Commented out workflow rendering code
                {workflows.map((workflow) => (
                    <Link
                        to={`/users/${user.uid}/workflows/${workflow.id}`}
                        className={`card-link card template-card card-color-${switchChecked}`}
                    >
                        <div className="ttl">
                            {workflow.templateName?.toUpperCase()}
                        </div>
                        <p>Personal</p>
                    </Link>
                ))} */}
				{templates.map((template) => (
					<div
						key={template.id}
						className={`card-link card template-card card-color-${switchChecked} card-border`}
						style={{ borderTop: "2px solid transparent" }}
						onClick={() => handleCreateFromTemplate(template)}
					>
						<div className="ttl">{template.templateName.toUpperCase()}</div>
						<p>Pro Flow</p>
						<button className="main-content-button home-button">
							Use Pro Flow
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default ProFlows;
