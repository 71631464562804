import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Adjust this import based on your project structure
import "../Styles/Login.css";

const PasswordReset = ({ switchChecked }) => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	// Extract oobCode from URL parameters
	const queryParams = new URLSearchParams(location.search);
	const oobCode = queryParams.get("oobCode");

	useEffect(() => {
		if (!oobCode) {
			navigate("/login");
		}
	}, [oobCode, navigate]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (newPassword !== confirmPassword) {
			setError("Passwords don't match");
			return;
		}

		try {
			await confirmPasswordReset(auth, oobCode, newPassword);
			setSuccess(true);
			setTimeout(() => navigate("/login"), 3000);
		} catch (error) {
			setError(error.message);
		}
	};

	if (success) {
		return <div>Password reset successful! Redirecting to login...</div>;
	}

	return (
		<div className={`loginContent main-color-${switchChecked}`}>
			<img src="/bgLogin.jpg" alt="backgroundImg" className="backgroundImg" />
			<div className="SpinFlowTitle">
				<img src="/newLogo512.png" alt="spinFlow Logo" />
			</div>
			<div className={`loginBox card-color-${switchChecked}`}>
				<h2>Reset Your Password</h2>
				<form onSubmit={handleSubmit}>
					<input
						type="password"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						placeholder="New Password"
						required
					/>
					<input
						type="password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						placeholder="Confirm New Password"
						required
					/>
					<button type="submit">Reset Password</button>
				</form>
				{error && <p style={{ color: "red" }}>{error}</p>}
			</div>
		</div>
	);
};

export default PasswordReset;
