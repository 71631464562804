export const model = "gpt-4o"; // Specify the model to use
export const viewType = "prompt"; // Default view type
export const templateName = "Sales Lead Qualification Flow"; // Template name for the assistant
export const defaultTemplateName = "Sales Lead Qualification Flow";

// Array of prompt objects
export const prompts = [
	{
		id: "prompt-1",
		title: "Lead Scoring Agent",
		content: `Your task is to evaluate incoming leads and assign initial scores. Follow these steps:

1. Review the lead's information thoroughly.
2. Assess the following key elements:
   a. Company size and industry
   b. Job title and decision-making authority of the contact
   c. Budget indication (if available)
   d. Timeline for potential purchase
   e. Current solutions or competitors they're using
   f. Engagement level (e.g., website visits, content downloads, email opens)
3. Assign a score based on our predefined criteria for each element.
4. Calculate the overall lead score.
5. Categorize the lead (e.g., hot, warm, cold) based on the total score.
6. Provide a brief explanation for the scoring and categorization.

Present your assessment in a clear, structured format. Use a numerical scale for individual elements and the overall score. Conclude with a recommendation for the next step in the sales process based on the lead's score and category.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-2",
		title: "Industry Analysis Agent",
		content: `Your task is to provide relevant industry-specific information and potential pain points for the lead. Follow these steps:

1. Identify the lead's industry based on the information provided.
2. Research and summarize the following:
   a. Current trends and challenges in the industry
   b. Common pain points for businesses in this sector
   c. Typical buying processes and decision-making factors
   d. Key competitors or solutions commonly used in the industry
   e. Relevant regulations or compliance requirements
3. Identify how our product/service addresses industry-specific needs or challenges.
4. Suggest industry-specific talking points or value propositions.

Present your analysis in a concise, bullet-point format. Prioritize information that is most relevant to our sales process and product offering. Conclude with 2-3 key insights that could be particularly valuable in engaging with this lead.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-3",
		title: "Communication Style Agent",
		content: `Your task is to analyze the lead's data and suggest an appropriate communication style and messaging. Follow these steps:

1. Review all available information about the lead, including:
   a. Job title and seniority
   b. Previous interactions with our company
   c. Social media presence and activity
   d. Any personal information or preferences noted
2. Determine the most appropriate communication style:
   a. Formal vs. informal
   b. Technical vs. non-technical
   c. Direct vs. relationship-building
3. Suggest key messaging points based on:
   a. The lead's likely priorities and pain points
   b. Our unique value proposition for their specific situation
4. Recommend the best communication channels (e.g., email, phone, LinkedIn).
5. Provide guidelines for personalizing future communications.

Present your suggestions in a clear, actionable format. Include examples of language or phrases that align with the recommended communication style. Conclude with any specific dos and don'ts for communicating with this lead.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-4",
		title: "Follow-up Strategy Agent",
		content: `Your task is to create a customized follow-up plan based on the lead score, industry insights, and communication style recommendations. Follow these steps:

1. Review the outputs from the previous agents (Lead Scoring, Industry Analysis, and Communication Style).
2. Develop a multi-step follow-up plan, including:
   a. Timing of each touch point (e.g., immediate, 2 days, 1 week, 1 month)
   b. Channel for each communication (e.g., email, phone, social media)
   c. Objective for each interaction (e.g., provide information, schedule a demo, address concerns)
   d. Key talking points or content to be shared at each stage
3. Suggest triggers for adjusting the plan (e.g., if the lead engages with certain content or reaches out proactively).
4. Recommend criteria for passing the lead to sales or disqualifying it.
5. Propose a method for tracking the lead's engagement throughout the follow-up process.

Present your follow-up plan in a clear, chronological format. Include templates or outlines for key communications. Conclude with metrics to measure the success of the follow-up strategy and suggestions for ongoing refinement of the process.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
];
