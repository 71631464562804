import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCirclePlus,
	faPenToSquare,
	faCircleCheck,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "./Styles/MainFlowBody.css";
import { db, storage } from "../../firebaseConfig";
import {
	doc,
	setDoc,
	getDoc,
	collection,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import {
	ref,
	getBlob,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";
import { useParams } from "react-router-dom";
import OpenAI from "openai";
import { scrapeUrl } from "./ScraperFlow";
import { v4 as uuidv4 } from "uuid";
import { marked } from "marked";

// Import the bot configuration
import {
	model,
	viewType as defaultViewType,
	templateName as defaultTemplateName,
	prompts as templatePrompts,
} from "./FlowsData/MarketingStrategyFlow";

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const getFileContent = async (fileUrl) => {
	try {
		const fileRef = ref(storage, fileUrl);
		const blob = await getBlob(fileRef);
		let fileName = fileUrl.split("/").pop();
		fileName = fileName.split("?")[0];
		return new File([blob], fileName, { type: blob.type });
	} catch (error) {
		console.error("Error fetching file content:", error);
		throw error;
	}
};

const isFileTypeSupported = (fileName) => {
	const supportedExtensions = [".txt", ".pdf", ".docx", ".md"];
	const extension = "." + fileName.split(".").pop().toLowerCase();
	return supportedExtensions.includes(extension);
};

const createVectorStoreAndUploadFiles = async (fileUrls) => {
	try {
		const vectorStore = await openai.beta.vectorStores.create({
			name: "KnowledgeBaseStore",
		});

		const files = await Promise.all(
			fileUrls.map(async (fileUrl) => {
				const file = await getFileContent(fileUrl);
				if (!isFileTypeSupported(file.name)) {
					throw new Error(`Unsupported file type: ${file.name}`);
				}
				console.log(`Uploading file: ${file.name}, type: ${file.type}`);
				return file;
			})
		);

		await openai.beta.vectorStores.fileBatches.uploadAndPoll(vectorStore.id, {
			files,
		});

		return vectorStore.id;
	} catch (error) {
		console.error("Error creating vector store and uploading files:", error);
		throw error;
	}
};

const createAssistantWithFiles = async (prompt) => {
	try {
		const vectorStoreId = await createVectorStoreAndUploadFiles(
			prompt.knowledgeBases
		);

		const assistantResponse = await openai.beta.assistants.create({
			name: prompt.title || "New Assistant",
			instructions: prompt.content || "You are an assistant.",
			model: prompt.model || model,
			tools: [{ type: "file_search" }],
			tool_resources: {
				file_search: {
					vector_store_ids: [vectorStoreId],
				},
			},
		});

		const assistantId = assistantResponse.id || assistantResponse.data?.id;
		if (!assistantId) {
			throw new Error("Failed to retrieve assistant ID from response");
		}

		console.log(
			"Assistant created and files linked. Assistant ID:",
			assistantId
		);
		return assistantId;
	} catch (error) {
		console.error("Detailed error in createAssistantWithFiles:", error);
		throw error;
	}
};

const callOpenAI = async (prompt) => {
	try {
		if (prompt.knowledgeBases && prompt.knowledgeBases.length > 0) {
			const assistantId = await createAssistantWithFiles(prompt);
			if (!assistantId) {
				throw new Error("Failed to create assistant");
			}

			const thread = await openai.beta.threads.create();

			await openai.beta.threads.messages.create(thread.id, {
				role: "user",
				content: prompt.content,
			});

			const run = await openai.beta.threads.runs.create(thread.id, {
				assistant_id: assistantId,
			});

			let runStatus = await openai.beta.threads.runs.retrieve(
				thread.id,
				run.id
			);

			while (runStatus.status !== "completed") {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
			}

			const messages = await openai.beta.threads.messages.list(thread.id);
			return messages.data[0].content[0].text.value;
		} else {
			const response = await openai.chat.completions.create({
				model: prompt.model || model,
				messages: [
					{ role: "system", content: "You are an assistant." },
					{ role: "user", content: prompt.content },
				],
			});
			return response.choices[0].message.content;
		}
	} catch (error) {
		console.error("Error calling OpenAI API:", error);
		return `Error generating response: ${error.message}`;
	}
};

const callPerplexity = async (prompt) => {
	try {
		const response = await fetch("https://api.perplexity.ai/chat/completions", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_PERPLEXITY_API_KEY}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				model: prompt.model,
				messages: [
					{ role: "system", content: "You are an assistant." },
					{ role: "user", content: prompt.content },
				],
			}),
		});
		const data = await response.json();
		return data.choices[0].message.content;
	} catch (error) {
		console.error("Error calling Perplexity API:", error);
		return `Error generating response: ${error.message}`;
	}
};

const callAPI = async (prompt) => {
	if (prompt.model.includes("llama")) {
		return callPerplexity(prompt);
	} else {
		return callOpenAI(prompt);
	}
};

const calculateTokens = (text) => {
	return text.split(/\s+/).length + Math.floor(text.length / 4);
};

const newPromptTemplate = {
	id: "",
	title: "New Task",
	content: "Your task is to ...",
	model: model,
	knowledgeBases: [],
	fineTunedModel: "",
	viewType: defaultViewType,
};

function MainFlowBody({ setTokenCounters, user, switchChecked }) {
	const { flowId } = useParams();
	const [prompts, setPrompts] = useState([]);
	const [selectedPrompt, setSelectedPrompt] = useState(null);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [templateName, setTemplateName] = useState("");
	const [outputs, setOutputs] = useState([]);
	const [personalFiles, setPersonalFiles] = useState([]);
	const [sharedFiles, setSharedFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState([]);
	const [linkedPrompts, setLinkedPrompts] = useState({});
	// eslint-disable-next-line
	const [tokenCount, setTokenCount] = useState(0);
	const totalTokenCount = useRef(0);
	const filesToDelete = useRef([]);
	// eslint-disable-next-line
	const [viewType, setViewType] = useState(defaultViewType);
	const saveTimeoutRef = useRef(null);
	// eslint-disable-next-line
	const [useTemplate, setUseTemplate] = useState(true);
	// eslint-disable-next-line
	const [flowLevel, setFlowLevel] = useState("manager");
	const [teamId, setTeamId] = useState("");
	// eslint-disable-next-line
	const [models, setModels] = useState([model, "gpt-4o-mini", "gpt-4o"]);
	const [departments, setDepartments] = useState([]);
	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [isSharing, setIsSharing] = useState(false);
	const [nameIsSet, setNameIsSet] = useState(false);
	// eslint-disable-next-line
	const [isInputVisible, setIsInputVisible] = useState(false);
	const [userInput, setUserInput] = useState("");
	const [newFlowIdea, setNewFlowIdea] = useState("");
	const [isCreatingNewFlow, setIsCreatingNewFlow] = useState(false);
	const [viewOutputsPrompts, setViewOutputsPrompts] = useState(false);

	const ASSISTANT_ID = "asst_wvnRY4GQE4SeZzCZAOMSLKZf";

	const fetchAssistant = useCallback(
		async (retryCount = 0) => {
			try {
				if (flowId) {
					const userDocRef = doc(db, `users/${user.uid}`);
					const userDocSnap = await getDoc(userDocRef);
					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						const teamId = userData.teamId;
						setTeamId(teamId);

						if (teamId) {
							const docRef = doc(db, `teams/${teamId}/flows`, flowId);
							const docSnap = await getDoc(docRef);
							if (docSnap.exists()) {
								const data = docSnap.data();
								setPrompts(data.prompts || []);
								setTemplateName(data.templateName || "");
								data.templateName && setNameIsSet(true);
								setLinkedPrompts(data.linkedPrompts || {});
								setFlowLevel(data.level || "manager");

								const teamDocRef = doc(db, `teams/${teamId}`);
								const teamDocSnap = await getDoc(teamDocRef);
								if (teamDocSnap.exists()) {
									const teamData = teamDocSnap.data();
									const initialTokenCount = teamData.tokenCount || 0;
									totalTokenCount.current = initialTokenCount;
									setTokenCount(initialTokenCount);
									setTokenCounters(initialTokenCount);
								}
							} else {
								if (useTemplate) {
									setPrompts(templatePrompts);
									setTemplateName(defaultTemplateName);
									setNameIsSet(true);
								} else {
									setPrompts([]);
									setTemplateName("");
								}
							}
						} else {
							console.error("User is not part of a team");
						}
					}
				} else {
					console.error("Missing flowId");
				}
			} catch (error) {
				console.error("Error fetching flow:", error);
			}
		},
		[flowId, user.uid, setTokenCounters, useTemplate]
	);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				setDepartments(
					departmentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
				);
			}
		};

		fetchDepartments();
	}, [teamId]);

	useEffect(() => {
		fetchAssistant();
	}, [fetchAssistant]);

	const handleDepartmentChange = (e) => {
		const departmentId = e.target.value;
		setSelectedDepartments((prev) =>
			prev.includes(departmentId)
				? prev.filter((id) => id !== departmentId)
				: [...prev, departmentId]
		);
	};

	const fetchFiles = useCallback(async () => {
		try {
			if (!user.uid) return;

			// Fetch personal files
			const personalFilesQuery = query(
				collection(db, `users/${user.uid}/knowledgebase`)
			);
			const personalFilesSnapshot = await getDocs(personalFilesQuery);
			const personalFilesList = personalFilesSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
				isPersonal: true,
			}));
			setPersonalFiles(personalFilesList);

			// Fetch shared files if user is part of a team
			if (teamId) {
				const sharedFilesQuery = query(
					collection(db, `teams/${teamId}/knowledgebase`),
					where("sharedWith", "array-contains", user.email)
				);
				const sharedFilesSnapshot = await getDocs(sharedFilesQuery);
				const sharedFilesList = sharedFilesSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
					isPersonal: false,
				}));
				setSharedFiles(sharedFilesList);
			}
		} catch (error) {
			console.error("Error fetching files: ", error);
		}
	}, [user.uid, teamId, user.email]);

	useEffect(() => {
		fetchFiles();
	}, [fetchFiles]);

	const addNewPrompt = (index) => {
		const newPrompt = {
			...newPromptTemplate,
			id: `prompt-${prompts.length + 1}`,
		};
		const newPrompts = [...prompts];
		newPrompts.splice(index + 1, 0, newPrompt);
		setPrompts(newPrompts);
		savePromptsToFirestore(newPrompts, templateName, linkedPrompts);
	};

	const handlePromptClick = (prompt) => {
		if (!prompt.knowledgeBases) {
			prompt.knowledgeBases = [];
		}
		if (selectedPrompt === prompt) {
			setSelectedPrompt(null);
			setIsSidebarOpen(false);
		} else {
			setSelectedPrompt(prompt);
			setViewType(prompt.viewType || "prompt");
			setIsSidebarOpen(true);
		}
	};

	const handlePromptChange = (e) => {
		const { name, value, options } = e.target;
		let newValue = value;
		if (name === "knowledgeBases") {
			newValue = Array.from(options)
				.filter((option) => option.selected)
				.map((option) => option.value);
		}
		setSelectedPrompt((prev) => ({ ...prev, [name]: newValue }));
		const updatedPrompts = prompts.map((prompt) =>
			prompt.id === selectedPrompt.id
				? { ...selectedPrompt, [name]: newValue }
				: prompt
		);
		setPrompts(updatedPrompts);
		savePromptsToFirestore(updatedPrompts, templateName, linkedPrompts);
	};
	// eslint-disable-next-line
	const handleUnlinkKnowledgeBase = (file) => {
		const newKnowledgeBases = selectedPrompt.knowledgeBases.filter(
			(kb) => kb !== file.file
		);
		setSelectedPrompt((prev) => ({
			...prev,
			knowledgeBases: newKnowledgeBases,
		}));
		const updatedPrompts = prompts.map((prompt) =>
			prompt.id === selectedPrompt.id
				? { ...prompt, knowledgeBases: newKnowledgeBases }
				: prompt
		);
		setPrompts(updatedPrompts);
		savePromptsToFirestore(updatedPrompts, templateName, linkedPrompts);
	};

	const handleBlur = () => {
		savePromptsToFirestore(prompts, templateName, linkedPrompts);
	};

	const handleTemplateNameChange = (e) => {
		setTemplateName(e.target.value);
	};

	const handleTemplateNameBlur = () => {
		savePromptsToFirestore(prompts, templateName, linkedPrompts);
	};

	const savePromptsToFirestore = async (
		updatedPrompts,
		templateName,
		linkedPrompts
	) => {
		try {
			const isPersonal = selectedDepartments.length === 0;
			if (teamId && flowId) {
				await setDoc(doc(db, "teams", teamId, "flows", flowId), {
					prompts: updatedPrompts,
					templateName: templateName,
					linkedPrompts: linkedPrompts,
					createdAt: new Date(),
					level: "staff",
					createdBy: user.uid,
					departments: selectedDepartments,
					isPersonal: isPersonal,
				});
			} else {
				console.error("Missing teamId or flowId");
			}
		} catch (error) {
			console.error("Error saving prompts to Firestore: ", error);
		}
	};

	const saveTokenCountToFirestore = async (currentTokenCount) => {
		try {
			if (teamId) {
				const teamDocRef = doc(db, `teams/${teamId}`);
				await updateDoc(teamDocRef, {
					tokenCount: currentTokenCount,
				});
				setTokenCounters(currentTokenCount);
			} else {
				console.error("Missing teamId");
			}
		} catch (error) {
			console.error("Error saving token count to Firestore:", error);
		}
	};

	const handleGenerateResponses = async () => {
		setLoading(true);
		setProgress([]);
		setOutputs([]);
		const newOutputs = [];

		for (let i = 0; i < prompts.length; i++) {
			const prompt = prompts[i];
			let promptContent = prompt.content;

			const linkedPromptId = linkedPrompts[prompt.id];
			if (linkedPromptId) {
				const linkedOutput = newOutputs.find(
					(output) => output.promptId === linkedPromptId
				);
				if (linkedOutput) {
					promptContent += `\n\nLinked Response:\n${linkedOutput.output}`;
				}
			}

			if (prompt.viewType === "action" && prompt.url) {
				setProgress((prev) => [...prev, "Scraping main page..."]);
				const scrapedText = await scrapeUrl(prompt.url);
				setProgress((prev) => [...prev, "Scraping subdomains..."]);
				promptContent += `\n\nScraped Data:\n${scrapedText}`;

				const fileName = `scraped_${uuidv4()}.txt`;
				const file = new Blob([scrapedText], { type: "text/plain" });
				const storageRef = ref(
					storage,
					`users/${user.uid}/knowledgebases/${fileName}`
				);
				await uploadBytes(storageRef, file);
				const fileUrl = await getDownloadURL(storageRef);

				if (i < prompts.length - 1) {
					const nextPrompt = { ...prompts[i + 1] };
					nextPrompt.knowledgeBases = [...nextPrompt.knowledgeBases, fileUrl];
					prompts[i + 1] = nextPrompt;
				}

				filesToDelete.current.push(storageRef);

				setProgress((prev) => [...prev, "Bundling into TXT..."]);
				newOutputs.push({
					promptId: prompt.id,
					output: "Scraping completed.",
				});

				setOutputs((prevOutputs) => [
					...prevOutputs,
					{ promptId: prompt.id, output: "Scraping completed." },
				]);

				const scrapedTokens = calculateTokens(scrapedText);
				totalTokenCount.current += scrapedTokens;
				setTokenCount(totalTokenCount.current);
				debounceSaveTokenCount(totalTokenCount.current);
			} else if (prompt.viewType === "prompt") {
				setProgress((prev) => [...prev, "Generating response..."]);
				const output = await callAPI({
					...prompt,
					content: promptContent,
				});
				newOutputs.push({
					promptId: prompt.id,
					promptName: prompt.title,
					output,
				});

				setOutputs((prevOutputs) => [
					...prevOutputs,
					{ promptId: prompt.id, promptName: prompt.title, output },
				]);

				const promptTokens = calculateTokens(prompt.content);
				const responseTokens = calculateTokens(output);
				totalTokenCount.current += promptTokens + responseTokens;
				setTokenCount(totalTokenCount.current);
				debounceSaveTokenCount(totalTokenCount.current);
			}
		}

		for (const fileRef of filesToDelete.current) {
			await deleteObject(fileRef);
		}

		setLoading(false);
		setViewOutputsPrompts(true);
	};

	const debounceSaveTokenCount = (currentTokenCount) => {
		if (saveTimeoutRef.current) {
			clearTimeout(saveTimeoutRef.current);
		}
		saveTimeoutRef.current = setTimeout(() => {
			saveTokenCountToFirestore(currentTokenCount);
		}, 1000);
	};

	const handleLinkedPromptChange = (e) => {
		const linkedPromptId = e.target.value;
		const newLinkedPrompts = {
			...linkedPrompts,
			[selectedPrompt.id]: linkedPromptId,
		};
		setLinkedPrompts(newLinkedPrompts);
		savePromptsToFirestore(prompts, templateName, newLinkedPrompts);
	};

	const formatApiResponse = (apiResponse) => {
		let formattedResponse = apiResponse.replace(/【\d+:\d+†source】/g, "");
		return marked(formattedResponse);
	};
	// eslint-disable-next-line
	const handleGenerateWithAIClick = () => {
		setIsInputVisible(true);
	};
	// eslint-disable-next-line
	const handleUserInputSubmit = async () => {
		if (!userInput) return;

		try {
			const thread = await openai.beta.threads.create();

			await openai.beta.threads.messages.create(thread.id, {
				role: "user",
				content: userInput,
			});

			const run = await openai.beta.threads.runs.create(thread.id, {
				assistant_id: ASSISTANT_ID,
			});

			let runStatus = await openai.beta.threads.runs.retrieve(
				thread.id,
				run.id
			);
			while (runStatus.status !== "completed") {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
			}

			const messages = await openai.beta.threads.messages.list(thread.id);

			const assistantResponse = messages.data
				.filter((message) => message.role === "assistant")
				.pop();

			if (assistantResponse) {
				setSelectedPrompt((prev) => ({
					...prev,
					content: assistantResponse.content[0].text.value,
				}));

				const updatedPrompts = prompts.map((prompt) =>
					prompt.id === selectedPrompt.id
						? {
								...selectedPrompt,
								content: assistantResponse.content[0].text.value,
						  }
						: prompt
				);
				setPrompts(updatedPrompts);
				savePromptsToFirestore(updatedPrompts, templateName, linkedPrompts);
			}

			setIsInputVisible(false);
			setUserInput("");
		} catch (error) {
			console.error("Error communicating with the assistant:", error);
		}
	};

	const handleKnowledgeBaseSelection = (file) => {
		setSelectedPrompt((prev) => {
			const newKnowledgeBases = prev.knowledgeBases.includes(file.file)
				? prev.knowledgeBases.filter((kb) => kb !== file.file)
				: [...prev.knowledgeBases, file.file];

			const updatedPrompt = { ...prev, knowledgeBases: newKnowledgeBases };

			setPrompts((prevPrompts) =>
				prevPrompts.map((prompt) =>
					prompt.id === updatedPrompt.id ? updatedPrompt : prompt
				)
			);

			savePromptsToFirestore(
				prompts.map((prompt) =>
					prompt.id === updatedPrompt.id ? updatedPrompt : prompt
				),
				templateName,
				linkedPrompts
			);

			return updatedPrompt;
		});
	};

	const handleNewFlowIdeaSubmit = async () => {
		if (!newFlowIdea) return;

		setLoading(true);
		try {
			const thread = await openai.beta.threads.create();

			await openai.beta.threads.messages.create(thread.id, {
				role: "user",
				content: `Create a new flow based on this idea: ${newFlowIdea}. Provide the JavaScript scheme to create the flow, including template name and prompts. Return the response as a JSON object within a code block.`,
			});

			const run = await openai.beta.threads.runs.create(thread.id, {
				assistant_id: ASSISTANT_ID,
			});

			let runStatus = await openai.beta.threads.runs.retrieve(
				thread.id,
				run.id
			);
			while (runStatus.status !== "completed") {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
			}

			const messages = await openai.beta.threads.messages.list(thread.id);

			const assistantResponse = messages.data
				.filter((message) => message.role === "assistant")
				.pop();

			if (assistantResponse) {
				// Extract JSON from the code block
				const jsonMatch = assistantResponse.content[0].text.value.match(
					/```(?:json|javascript)\n([\s\S]*?)\n```/
				);
				if (jsonMatch && jsonMatch[1]) {
					const jsonString = jsonMatch[1].trim();
					const flowScheme = JSON.parse(jsonString);

					setTemplateName(flowScheme.templateName);
					setPrompts(flowScheme.prompts);
					setNameIsSet(true);
					setIsCreatingNewFlow(false);

					// Save the new flow to Firestore
					await savePromptsToFirestore(
						flowScheme.prompts,
						flowScheme.templateName,
						{}
					);
				} else {
					throw new Error(
						"Unable to extract JSON from the assistant's response"
					);
				}
			} else {
				throw new Error("No response from the assistant");
			}

			setLoading(false);
		} catch (error) {
			console.error("Error creating new flow:", error);
			setLoading(false);
			// Add user-friendly error message
			alert("An error occurred while creating the new flow. Please try again.");
		}
	};

	const truncateContent = (content) => {
		const words = content.split(" ");
		if (words.length > 50) {
			return words.slice(0, 50).join(" ") + "...";
		}
		return content;
	};

	const handleDeletePrompt = (promptId) => {
		const confirmed = window.confirm(
			"Are you sure you want to delete this prompt? This action cannot be undone."
		);

		if (confirmed) {
			const updatedPrompts = prompts.filter((prompt) => prompt.id !== promptId);
			setPrompts(updatedPrompts);
			savePromptsToFirestore(updatedPrompts, templateName, linkedPrompts);
		}
	};

	const convertMarkdownToPlainText = (markdown) => {
		// Remove [source] references
		let plainText = markdown.replace(/【\d+:\d+†source】/g, "");

		// Convert headings
		plainText = plainText
			.replace(/^### (.*$)/gim, "\n$1\n") // H3 to plain text
			.replace(/^#### (.*$)/gim, "\n$1\n") // H4 to plain text

			// Convert bold text (e.g., **bold**)
			.replace(/\*\*(.*)\*\*/gim, "$1")

			// Convert list items (- or *)
			.replace(/^\s*[-*]\s+/gm, "- ");

		return plainText.trim(); // Return the formatted text without trailing spaces
	};

	// Copy function for individual output with markdown conversion
	const copyToClipboard = (text) => {
		const plainText = convertMarkdownToPlainText(text); // Convert markdown to plain text
		navigator.clipboard
			.writeText(plainText)
			.then(() => {
				alert("Copied to clipboard!");
			})
			.catch((err) => {
				console.error("Failed to copy: ", err);
			});
	};

	// Function to copy all outputs to clipboard
	const copyAllOutputsToClipboard = (outputs) => {
		const allOutputsText = outputs
			.map((output) => convertMarkdownToPlainText(output.output))
			.join("\n\n");
		copyToClipboard(allOutputsText);
	};

	return (
		<div
			className={`main-flow-body main-content ${
				isSidebarOpen && "shrink"
			} main-color-${switchChecked}`}
		>
			<h1>AI Workflow Builder</h1>
			{!viewOutputsPrompts ? (
				!nameIsSet ? (
					<div className="template-name-input">
						{isCreatingNewFlow ? (
							<>
								<input
									type="text"
									value={newFlowIdea}
									onChange={(e) => setNewFlowIdea(e.target.value)}
									className="flow-name main-flows-name"
									placeholder="Describe your new flow idea"
								/>
								<button
									onClick={handleNewFlowIdeaSubmit}
									disabled={loading}
									className="main-content-button home-button"
								>
									{loading ? "Creating..." : "Create Flow"}
								</button>
							</>
						) : (
							<>
								<input
									id="templateName"
									name="templateName"
									className="flow-name main-flows-name"
									type="text"
									value={templateName}
									onChange={handleTemplateNameChange}
									onBlur={handleTemplateNameBlur}
									placeholder="Enter Flow Name"
								/>
								<button
									onClick={() => setIsCreatingNewFlow(true)}
									className="main-content-button home-button"
									disabled={templateName.length < 2}
								>
									Create With AI
								</button>
								<button
									onClick={() => setNameIsSet(true)}
									className="main-content-button home-button"
									disabled={templateName.length < 2}
								>
									Create From Scratch
								</button>
							</>
						)}
					</div>
				) : (
					<>
						<input
							id="templateName"
							name="templateName"
							className="flow-name main-flows-name"
							type="text"
							value={templateName}
							onChange={handleTemplateNameChange}
							onBlur={handleTemplateNameBlur}
							placeholder="Enter Flow Name"
						/>
						<div>
							<button
								className="flow-share-btn"
								onClick={() => {
									setIsSharing(!isSharing);
									setSelectedDepartments([]);
								}}
							>
								{isSharing ? "Keep Private" : "Share Flow"}
							</button>
						</div>
						{isSharing && (
							<div className="department-selection">
								<h4>Select Departments:</h4>
								{departments.map((department) => (
									<div key={department.id}>
										<input
											type="checkbox"
											id={`dept-${department.id}`}
											value={department.id}
											checked={selectedDepartments.includes(department.id)}
											onChange={handleDepartmentChange}
										/>
										<label htmlFor={`dept-${department.id}`}>
											{department.name}
										</label>
									</div>
								))}
							</div>
						)}
						{!loading ? (
							<div className="container">
								{prompts.map((prompt, index) => (
									<React.Fragment key={prompt.id}>
										<div className={`prompt-box card-color-${switchChecked}`}>
											<div className="edit-flow">
												{isSidebarOpen && selectedPrompt?.id === prompt.id ? (
													<div
														onClick={() => handlePromptClick(prompt)}
														className="edit-done-flow"
													>
														<FontAwesomeIcon
															icon={faCircleCheck}
															onClick={() => handlePromptClick(prompt)}
															size="xl"
														/>
														<p>Done</p>
													</div>
												) : (
													<div
														onClick={() => handlePromptClick(prompt)}
														className="edit-done-flow"
													>
														<FontAwesomeIcon
															icon={faPenToSquare}
															onClick={() => handlePromptClick(prompt)}
															size="lg"
														/>
														<p>Edit</p>
													</div>
												)}
											</div>
											<div className="prompt-content">
												{selectedPrompt?.id !== prompt.id && (
													<div>
														<h3>{prompt.title.toUpperCase()}</h3>
														{prompt.viewType === "action" ? (
															<p>{prompt.url}</p>
														) : (
															<div>
																<p>{truncateContent(prompt.content)}</p>
																{prompt.knowledgeBases &&
																	prompt.knowledgeBases.length === 0 && (
																		<p
																			style={{
																				fontStyle: "italic",
																				color: "red",
																			}}
																		>
																			No knowledge base attached. Please attach
																			one or link to another prompt.
																		</p>
																	)}
															</div>
														)}
													</div>
												)}

												{isSidebarOpen && selectedPrompt?.id === prompt.id && (
													<div className="sidebarFlow">
														<h2>Edit Flow</h2>
														<div className="view-switcher">
															<label htmlFor="viewType">Select View: </label>
															<select
																id="viewType"
																name="viewType"
																value={selectedPrompt.viewType || "prompt"}
																onChange={(e) => {
																	setViewType(e.target.value);
																	handlePromptChange(e);
																}}
															>
																<option value="prompt">Prompt</option>
																<option value="action">Action</option>
															</select>
														</div>
														<h4>Flow Name</h4>
														<input
															name="title"
															className="flow-name"
															type="text"
															value={selectedPrompt.title}
															onChange={handlePromptChange}
															onBlur={handleBlur}
															placeholder="Title"
														/>
														{selectedPrompt.viewType === "prompt" ? (
															<>
																<div className="flow-prompt-area">
																	<h4>Prompt</h4>
																	<textarea
																		name="content"
																		className="flow-textarea"
																		value={selectedPrompt.content}
																		onChange={handlePromptChange}
																		onBlur={handleBlur}
																		rows="8"
																	/>
																</div>
																{selectedPrompt.id !== "prompt-1" && (
																	<>
																		<h4>Link to Previous Prompt</h4>
																		<select
																			name="linkedPrompt"
																			value={
																				linkedPrompts[selectedPrompt.id] || ""
																			}
																			onChange={handleLinkedPromptChange}
																		>
																			<option value="">Select a prompt</option>
																			{prompts
																				.filter(
																					(prompt) =>
																						prompt.id !== selectedPrompt.id
																				)
																				.map((prompt) => (
																					<option
																						key={prompt.id}
																						value={prompt.id}
																					>
																						{prompt.title}
																					</option>
																				))}
																		</select>
																	</>
																)}
																<h4>Model</h4>
																<select
																	name="model"
																	value={selectedPrompt.model}
																	onChange={handlePromptChange}
																	onBlur={handleBlur}
																>
																	{models.map((model) => (
																		<option key={model} value={model}>
																			{model}
																		</option>
																	))}
																</select>
																<h4>Knowledge Bases</h4>
																{/* <div className="knowledge-bases-list">
																{selectedPrompt.knowledgeBases &&
																	selectedPrompt.knowledgeBases.map((kb) => {
																		const file = [
																			...personalFiles,
																			...sharedFiles,
																		].find((f) => f.file === kb);
																		return (
																			<div
																				key={kb}
																				className="knowledge-base-item"
																			>
																				<span>{file ? file.name : kb}</span>
																				<button
																					className="unlink-button"
																					onClick={() =>
																						handleUnlinkKnowledgeBase(file)
																					}
																				>
																					<FontAwesomeIcon icon={faTimes} />
																				</button>
																			</div>
																		);
																	})}
															</div> */}
																<div className="file-list flow-file-list">
																	<div className="personal-list">
																		<h5>Personal Files</h5>
																		{personalFiles
																			.filter(
																				(file) =>
																					!/\.(png|jpg|jpeg|gif|svg)$/i.test(
																						file.name
																					)
																			) // Exclude image files
																			.map((file) => (
																				<div key={file.id}>
																					<input
																						type="checkbox"
																						id={`file-${file.id}`}
																						checked={selectedPrompt.knowledgeBases.includes(
																							file.file
																						)}
																						onChange={() =>
																							handleKnowledgeBaseSelection(file)
																						}
																					/>
																					<label htmlFor={`file-${file.id}`}>
																						{file.name}
																					</label>
																				</div>
																			))}
																	</div>
																	<div className="shared-list">
																		<h5>Shared Files</h5>
																		{sharedFiles
																			.filter(
																				(file) =>
																					!/\.(png|jpg|jpeg|gif|svg)$/i.test(
																						file.name
																					)
																			) // Exclude image files
																			.map((file) => (
																				<div key={file.id}>
																					<input
																						type="checkbox"
																						id={`file-${file.id}`}
																						checked={selectedPrompt.knowledgeBases.includes(
																							file.file
																						)}
																						onChange={() =>
																							handleKnowledgeBaseSelection(file)
																						}
																					/>
																					<label htmlFor={`file-${file.id}`}>
																						{file.name}
																					</label>
																				</div>
																			))}
																	</div>
																</div>
																<button
																	onClick={() => handleDeletePrompt(prompt.id)} // Add delete button
																	className="main-content-button home-button"
																	style={{ float: "right", color: "red" }}
																>
																	Remove Prompt
																</button>
															</>
														) : (
															<div className="action-flow">
																<label htmlFor="scrape-url">
																	URL to Scrape:{" "}
																</label>
																<input
																	id="scrape-url"
																	className="flow-name"
																	name="url"
																	type="text"
																	value={selectedPrompt.url || ""}
																	onChange={handlePromptChange}
																	onBlur={handleBlur}
																	placeholder="Enter URL to scrape"
																/>
																<button
																	onClick={() => handleDeletePrompt(prompt.id)} // Add delete button
																	className="main-content-button home-button"
																	style={{ float: "right", color: "red" }}
																>
																	Remove Prompt
																</button>
															</div>
														)}
													</div>
												)}
											</div>
										</div>
										<button
											onClick={() => addNewPrompt(index)}
											className="add-prompt-button"
										>
											<FontAwesomeIcon icon={faCirclePlus} size="xl" />
										</button>
									</React.Fragment>
								))}
								{prompts.length === 0 && (
									<div className="empty-prompts">
										<p>
											No prompts yet. Click the button below to add a new
											prompt.
										</p>
										<button
											onClick={() => addNewPrompt(-1)}
											className="add-first-prompt-button"
										>
											<FontAwesomeIcon icon={faCirclePlus} size="xl" /> Add
											First Prompt
										</button>
									</div>
								)}
							</div>
						) : (
							<div className="progress-monitor">
								<FontAwesomeIcon icon={faSpinner} spin />
								{progress.map((message, index) => (
									<div key={index} className="progress-message">
										{message}
									</div>
								))}
							</div>
						)}
						{!loading && (
							<button
								onClick={handleGenerateResponses}
								className="generate-responses-button main-content-button"
								disabled={loading}
							>
								{loading ? "Generating..." : "Generate Flow Outputs"}
							</button>
						)}
					</>
				)
			) : (
				<div className="outputs-container">
					<button
						onClick={() => copyAllOutputsToClipboard(outputs)}
						className="copy-button"
					>
						Copy All Outputs
					</button>

					<div className="outputs-container">
						{outputs.map((output) => (
							<div key={output.promptId} className="output-box prompt-box">
								<h2>
									{output.promptName
										? output.promptName + " Output"
										: "Scraper"}{" "}
								</h2>

								<button
									onClick={() => copyToClipboard(output.output)}
									className="copy-button"
								>
									Copy
								</button>

								<div
									dangerouslySetInnerHTML={{
										__html: formatApiResponse(output.output),
									}}
								/>
							</div>
						))}
					</div>
				</div>
			)}
			{outputs.length > 0 && (
				<button
					className="main-content-button prompt-output-viewbtn"
					onClick={() => setViewOutputsPrompts(!viewOutputsPrompts)}
				>
					{!viewOutputsPrompts ? "Show Outputs" : "Show Flow"}
				</button>
			)}
		</div>
	);
}

export default MainFlowBody;
