import React, { useState, useEffect } from "react";
import "./Styles/InTheWorksScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const InTheWorksScreen = ({ user }) => {
	const slogans = [
		"AI Trained by You, Perfected for You",
		"Your Insights, Our AI",
		"You Teach, AI Reaches",
		"AI Crafted by You, Powered for You",
		"From Your Mind to AI's Power",
		"Built by You, Boosted by AI",
		"AI Your Knowledge, Our Innovation",
		"Smart Solutions, Your Way",
		"AI Inspired by You, Driven for You",
		"Shaped by You, Accelerated by AI",
	];

	const [currentSlogan, setCurrentSlogan] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentSlogan((prevSlogan) => (prevSlogan + 1) % slogans.length);
		}, 4000); // Change slogan every 3 seconds

		return () => clearInterval(intervalId); // Cleanup on component unmount
	}, [slogans.length]);

	return (
		<div className="main-content flows-content">
			<div className="text-container">
				<h2>Magnificence is near!</h2>
				<h4>{slogans[currentSlogan]}</h4>
			</div>
			<div className="flows-container">
				<div className="gear-wrapper gear1">
					<FontAwesomeIcon icon={faGear} size="10x" />
				</div>
				<div className="gear-wrapper gear2">
					<FontAwesomeIcon icon={faGear} size="10x" spin spinReverse />
				</div>
				<div className="gear-wrapper gear3">
					<FontAwesomeIcon icon={faGear} size="10x" spin spinReverse />
				</div>
			</div>
		</div>
	);
};

export default InTheWorksScreen;
