// src/Components/Dashboard.js
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import MainContent from "./MainContent";
import KnowledgeBase from "../Hooks/Knowledgebase/KnowledgeBase.js";
import Templates from "../Hooks/Bots/Templates";
import "../Styles/Dashboard.css";
import AddKnowledgeBase from "../Hooks/Knowledgebase/AddKnowledgeBase.js";
import AllKnowledgeBase from "../Hooks/Knowledgebase/AllKnowledgeBase.js";
import SharedKnowledgeBase from "../Hooks/Knowledgebase/ShareKnowledgeBase.js";
import ProKnowledgeBase from "../Hooks/Knowledgebase/ProKnowledgeBase.js";
import SharedDepartments from "../Hooks/Departments/SharedDepartments.js";
import ProDepartments from "../Hooks/Departments/ProDepartments.js";
import PersonalDepartments from "../Hooks/Departments/PersonalDepartments.js";
import Assistants from "../Hooks/Bots/Assistants.js";
import SharedAssistants from "../Hooks/Bots/SharedAssistants.js";
import ProAssistant from "../Hooks/Bots/ProAssistant.js";
import MyFlow from "../Hooks/Flows/MyFlows.js";
import SharedFlows from "../Hooks/Flows/SharedFlows.js";
import Documentation from "./Documentation.js";
import AssistantChat from "../Hooks/Bots/AssistantChat";
import BotTemplate from "../Hooks/Bots/BotTemplate";
import TeamMembers from "./TeamMembers";
import VoiceUpload from "../Hooks/Knowledgebase/VoiceUpload.js";
import Login from "./Login";
import MainDepartments from "../Hooks/Departments/MainDepartments.js";
import ProFlows from "../Hooks/Flows/ProFlows.js";
import MessagingPage from "./MessagingPage";
// import ReferenceCode from "./ReferenceCode";
import Signup from "./Signup";
import {
	botName as MarketingBotName,
	instructions as MarketingInstructions,
	description as MarketingDescription,
} from "../Hooks/Bots/BotData/MarketingStrategyBot";
import {
	botName as CustomerServiceBotName,
	instructions as CustomerServiceInstructions,
	description as CustomerServiceDescription,
} from "../Hooks/Bots/BotData/CustomerServiceBot";
import {
	botName as DataAnalysisBotName,
	instructions as DataAnalysisInstructions,
	description as DataAnalysisDescription,
} from "../Hooks/Bots/BotData/DataAnalysisBot";
import {
	botName as GeneralKnowledgeBotName,
	instructions as GeneralKnowledgeInstuctions,
	description as GeneralKnowledgeDescription,
} from "../Hooks/Bots/BotData/AllKnowledgeBot";
import {
	botName as SocialMediaBotName,
	instructions as SocialMediaInstructions,
	description as SocialMediaDescription,
} from "../Hooks/Bots/BotData/SocialMediaBot";
import {
	botName as HumanResourcesBotName,
	instructions as HumanResourcesInstructions,
	description as HumanResourcesDescription,
} from "../Hooks/Bots/BotData/HumanResourcesBot";
import {
	botName as ProjectManagementBotName,
	instructions as ProjectManagementInstructions,
	description as ProjectManagementDescription,
} from "../Hooks/Bots/BotData/ProjectManagementBot";
import {
	botName as SupplyChainBotName,
	instructions as SupplyChainInstructions,
	description as SupplyChainDescription,
} from "../Hooks/Bots/BotData/SupplyChainBot";
import {
	botName as ITSupportBotName,
	instructions as ITSupportInstructions,
	description as ITSupportDescription,
} from "../Hooks/Bots/BotData/ITSupportBot";
import {
	botName as LegalComplianceBotName,
	instructions as LegalComplianceInstructions,
	description as LegalComplianceDescription,
} from "../Hooks/Bots/BotData/LegalComplianceBot";
import {
	botName as ProductDevelopmentBotName,
	instructions as ProductDevelopmentInstructions,
	description as ProductDevelopmentDescription,
} from "../Hooks/Bots/BotData/ProductDevelopmentBot";
import {
	botName as SalesOptimizationBotName,
	instructions as SalesOptimizationInstructions,
	description as SalesOptimizationDescription,
} from "../Hooks/Bots/BotData/SalesOptimizationBot";
import {
	botName as BusinessIntelligenceBotName,
	instructions as BusinessIntelligenceInstructions,
	description as BusinessIntelligenceDescription,
} from "../Hooks/Bots/BotData/BusinessIntelligenceBot";
import {
	botName as EventPlanningBotName,
	instructions as EventPlanningInstructions,
	description as EventPlanningDescription,
} from "../Hooks/Bots/BotData/EventPlanningBot";
import {
	botName as TrainingDevelopmentBotName,
	instructions as TrainingDevelopmentInstructions,
	description as TrainingDevelopmentDescription,
} from "../Hooks/Bots/BotData/TrainingDevelopmentBot";
import {
	botName as DigitalMarketingBotName,
	instructions as DigitalMarketingInstructions,
	description as DigitalMarketingDescription,
} from "../Hooks/Bots/BotData/DigitalMarketingBot";
import {
	botName as SOPBotName,
	instructions as SOPInstructions,
	description as SOPDescription,
} from "../Hooks/Bots/BotData/SOPBot";
import CreateBotForm from "../Hooks/Bots/CreateBotForm";
import InTheWorksScreen from "../Hooks/InDevelopment/InTheWorksScreen.js";
import MainFlowBody from "../Hooks/Flows/MainFlowBody";
import WorkflowList from "../Hooks/Flows/WorkflowList";
import AccountTypeSelection from "./AccountTypeSelection";
import InterviewBot from "../Hooks/Knowledgebase/InterviewBot.js";
import Credentials from "./Credentials";
import EditSubscription from "./EditSubscription";
import { db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Help from "./Help.js";

const stripePromise = loadStripe(
	"pk_test_51PXl42HauYUkGvLIWrz4gnqR7oracH3obc9hMDRBJSAXicLJh3kA4aSmg3lNNBpWULW90TZxBFVBm3yYcygLACF900lWxt1JxG"
);

const Dashboard = ({
	user,
	switchChecked,
	setSwitchChecked,
	startingTokens,
	tokenCount,
	selectedDepartment,
	setSelectedDepartment,
}) => {
	// eslint-disable-next-line
	const [tokenCounters, setTokenCounters] = useState(0);
	const [isSignup, setIsSignup] = useState(false);
	const [teamId, setTeamId] = useState("");
	// eslint-disable-next-line
	const [showAccountTypeModal, setShowAccountTypeModal] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			if (user && user.uid) {
				try {
					const userDoc = await getDoc(doc(db, "users", user.uid));
					if (userDoc.exists()) {
						const userData = userDoc.data();
						if (!userData.accountType) {
							setShowAccountTypeModal(true);
						}
						setTeamId(userData.teamId);
					}
				} catch (error) {
					console.error("Error fetching user data: ", error);
				}
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		const checkTrialStatus = async () => {
			if (user && user.uid) {
				try {
					const userDoc = await getDoc(doc(db, `users/${user.uid}`));
					const userData = userDoc.data();
					if (userData.teamId) {
						const teamDoc = await getDoc(doc(db, `teams/${userData.teamId}`));
						const teamData = teamDoc.data();
						if (teamData.accountType === "FREE TRIAL") {
							const trialEndDate = teamData.trialEndDate.toDate();
							const tokensLeft = teamData.tokens - (teamData.tokenCount || 0);
							if (trialEndDate < new Date() || tokensLeft <= 0) {
								setShowAccountTypeModal(true);
							}
						}
					} else if (!userData.accountType) {
						// If user doesn't have a team or account type, show modal
						setShowAccountTypeModal(true);
					}
				} catch (error) {
					console.error("Error checking trial status: ", error);
				}
			}
		};

		checkTrialStatus();
		// Set up an interval to check regularly
		const intervalId = setInterval(checkTrialStatus, 60000); // Check every minute

		return () => clearInterval(intervalId);
	}, [user]);

	const handleCloseModal = () => {
		setShowAccountTypeModal(false);
	};

	if (!user) {
		return isSignup ? (
			<Signup setIsSignup={setIsSignup} switchChecked={switchChecked} />
		) : (
			<Login setIsSignup={setIsSignup} switchChecked={switchChecked} />
		);
	}

	return (
		<div className="dashboard">
			<Sidebar
				tokenCounters={tokenCounters}
				user={user}
				setSwitchChecked={setSwitchChecked}
				switchChecked={switchChecked}
				startingTokens={startingTokens}
				tokenCount={tokenCount}
			/>
			<div className="main">
				<Header
					user={user}
					switchChecked={switchChecked}
					selectedDepartment={selectedDepartment}
					setSelectedDepartment={setSelectedDepartment}
				/>
				{showAccountTypeModal && (
					<>
						<div className="modal-overlay"></div>
						<AccountTypeSelection
							user={user}
							onClose={handleCloseModal}
							switchChecked={switchChecked}
						/>
					</>
				)}
				<Routes>
					<Route
						path="/"
						element={
							<MainContent
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
							/>
						}
					/>
					<Route
						path="/In-The-Works-Screen"
						element={
							<InTheWorksScreen user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/knowledge-base"
						element={
							<KnowledgeBase user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/add-knowledge-base"
						element={
							<AddKnowledgeBase user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/shared-knowledge-base"
						element={
							<SharedKnowledgeBase
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/pro-knowledge-base"
						element={
							<ProKnowledgeBase user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/all-knowledge-base"
						element={
							<AllKnowledgeBase
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/departments-main"
						element={
							<MainDepartments user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/shared-departments"
						element={
							<SharedDepartments user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/pro-departments"
						element={
							<ProDepartments user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/personal-departments"
						element={
							<PersonalDepartments user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/voice-knowledge-base"
						element={<VoiceUpload user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/templates"
						element={<Templates user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/team-members"
						element={<TeamMembers user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/information"
						element={<Help user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/create-custom-bot"
						element={
							<CreateBotForm user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/interview"
						element={<InterviewBot user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/credentials"
						element={<Credentials user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/social-media-bot"
						element={
							<BotTemplate
								user={user}
								botName={SocialMediaBotName}
								instructions={SocialMediaInstructions}
								description={SocialMediaDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/messages"
						element={
							<MessagingPage
								userId={user.uid}
								teamId={teamId}
								switchChecked={switchChecked}
								user={user}
							/>
						}
					/>
					<Route
						path="/customer-service-bot"
						element={
							<BotTemplate
								user={user}
								botName={CustomerServiceBotName}
								instructions={CustomerServiceInstructions}
								description={CustomerServiceDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/supply-chain-bot"
						element={
							<BotTemplate
								user={user}
								botName={SupplyChainBotName}
								instructions={SupplyChainInstructions}
								description={SupplyChainDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/it-support-bot"
						element={
							<BotTemplate
								user={user}
								botName={ITSupportBotName}
								instructions={ITSupportInstructions}
								description={ITSupportDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/sop-bot"
						element={
							<BotTemplate
								user={user}
								botName={SOPBotName}
								instructions={SOPInstructions}
								description={SOPDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/legal-compliance-bot"
						element={
							<BotTemplate
								user={user}
								botName={LegalComplianceBotName}
								instructions={LegalComplianceInstructions}
								description={LegalComplianceDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/product-development-bot"
						element={
							<BotTemplate
								user={user}
								botName={ProductDevelopmentBotName}
								instructions={ProductDevelopmentInstructions}
								switchChecked={switchChecked}
								description={ProductDevelopmentDescription}
							/>
						}
					/>
					<Route
						path="/digital-marketing-bot"
						element={
							<BotTemplate
								user={user}
								botName={DigitalMarketingBotName}
								instructions={DigitalMarketingInstructions}
								description={DigitalMarketingDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/sales-optimization-bot"
						element={
							<BotTemplate
								user={user}
								botName={SalesOptimizationBotName}
								instructions={SalesOptimizationInstructions}
								description={SalesOptimizationDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/supply-chain-bot"
						element={
							<BotTemplate
								user={user}
								botName={SupplyChainBotName}
								instructions={SupplyChainInstructions}
								description={SupplyChainDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/data-analysis-bot"
						element={
							<BotTemplate
								user={user}
								botName={DataAnalysisBotName}
								instructions={DataAnalysisInstructions}
								description={DataAnalysisDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/human-resources-bot"
						element={
							<BotTemplate
								user={user}
								botName={HumanResourcesBotName}
								instructions={HumanResourcesInstructions}
								description={HumanResourcesDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/market-strategy-bot"
						element={
							<BotTemplate
								user={user}
								botName={MarketingBotName}
								instructions={MarketingInstructions}
								description={MarketingDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/event-planning-bot"
						element={
							<BotTemplate
								user={user}
								botName={EventPlanningBotName}
								instructions={EventPlanningInstructions}
								description={EventPlanningDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/project-management-bot"
						element={
							<BotTemplate
								user={user}
								botName={ProjectManagementBotName}
								instructions={ProjectManagementInstructions}
								description={ProjectManagementDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/general-knowledge-bot"
						element={
							<BotTemplate
								user={user}
								botName={GeneralKnowledgeBotName}
								instructions={GeneralKnowledgeInstuctions}
								description={GeneralKnowledgeDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/business-intelligence-bot"
						element={
							<BotTemplate
								user={user}
								botName={BusinessIntelligenceBotName}
								instructions={BusinessIntelligenceInstructions}
								description={BusinessIntelligenceDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/training-development-bot"
						element={
							<BotTemplate
								user={user}
								botName={TrainingDevelopmentBotName}
								instructions={TrainingDevelopmentInstructions}
								description={TrainingDevelopmentDescription}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/my-agents"
						element={
							<Assistants
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/shared-agents"
						element={
							<SharedAssistants
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/pro-agents"
						element={<ProAssistant user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/my-flows"
						element={
							<MyFlow
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/shared-flows"
						element={
							<SharedFlows
								user={user}
								switchChecked={switchChecked}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/documentation"
						element={
							<Documentation user={user} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/manage-subscription"
						element={
							<Elements stripe={stripePromise}>
								<EditSubscription user={user} switchChecked={switchChecked} />
							</Elements>
						}
					/>
					<Route
						path="/chat/:id"
						element={
							<AssistantChat
								setTokenCounters={setTokenCounters}
								user={user}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/users/:userId/workflows"
						element={<WorkflowList user={user} switchChecked={switchChecked} />}
					/>
					<Route
						path="/users/:userId/workflows/:flowId"
						element={
							<MainFlowBody
								user={user}
								setTokenCounters={setTokenCounters}
								switchChecked={switchChecked}
							/>
						}
					/>
					<Route
						path="/pro-flows"
						element={
							<ProFlows
								user={user}
								setTokenCounters={setTokenCounters}
								switchChecked={switchChecked}
							/>
						}
					/>
				</Routes>
			</div>
		</div>
	);
};

export default Dashboard;
