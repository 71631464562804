import React, { useState, useEffect, useCallback } from "react";
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Grid,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { db, storage } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	query,
	doc,
	getDoc,
	updateDoc,
	deleteDoc,
	where,
} from "firebase/firestore";
import {
	ref,
	getDownloadURL,
	deleteObject,
	uploadBytes,
	getMetadata,
} from "firebase/storage";
import "./Styles/KnowledgeBase.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileLines,
	faFloppyDisk,
	faTimes,
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFileCsv,
	faFileAudio,
	faFileVideo,
	faFileAlt,
	faTrashAlt,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";

const SharedKnowledgeBase = ({ user, switchChecked, selectedDepartment }) => {
	const [sharedFiles, setSharedFiles] = useState([]);
	const [teamId, setTeamId] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [popupContent, setPopupContent] = useState(null);
	const [popupType, setPopupType] = useState("");
	const [editContent, setEditContent] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [currentFileId, setCurrentFileId] = useState(null);
	const [currentFileName, setCurrentFileName] = useState("");
	const [currentFileSharedWith, setCurrentFileSharedWith] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [currentFile, setCurrentFile] = useState(null); // <-- Added state for currentFile
	// eslint-disable-next-line
	const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState("");
	const [loading, setLoading] = useState(true);
	const [showSharedWithSection, setShowSharedWithSection] = useState(false);
	const [viewMode, setViewMode] = useState("cards");

	const fetchFiles = useCallback(async () => {
		try {
			const userDoc = await getDoc(doc(db, `users/${user.uid}`));
			if (userDoc.exists()) {
				const userData = userDoc.data();
				setTeamId(userData.teamId);

				if (userData.teamId) {
					const departmentsSnapshot = await getDocs(
						collection(db, `teams/${userData.teamId}/departments`)
					);
					const departmentsData = departmentsSnapshot.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}));
					setDepartments(departmentsData);

					const sharedFilesQuery = query(
						collection(db, `teams/${userData.teamId}/knowledgebase`),
						where("sharedWith", "array-contains", user.email)
					);

					const departmentSharedFilesQuery = query(
						collection(db, `teams/${userData.teamId}/knowledgebase`),
						where("sharedDepartments", "!=", [])
					);

					const [sharedFilesSnapshot, departmentSharedFilesSnapshot] =
						await Promise.all([
							getDocs(sharedFilesQuery),
							getDocs(departmentSharedFilesQuery),
						]);

					const allSharedFiles = [
						...sharedFilesSnapshot.docs,
						...departmentSharedFilesSnapshot.docs,
					].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

					const filesWithSize = await Promise.all(
						allSharedFiles.map(async (doc) => {
							const data = doc.data();
							const storageRef = ref(storage, data.file);
							const metadata = await getMetadata(storageRef);
							return { id: doc.id, ...data, size: metadata.size };
						})
					);

					setSharedFiles(filesWithSize);
					setLoading(false);
				}
			}
		} catch (error) {
			console.error("Error fetching files: ", error);
		}
	}, [user.uid, user.email]);

	useEffect(() => {
		fetchFiles();
	}, [fetchFiles]);

	const handleDeleteFile = async (file) => {
		if (
			!window.confirm(
				"Are you sure you want to remove this file from your shared files?"
			)
		) {
			return;
		}

		try {
			const updatedSharedWith = file.sharedWith.filter(
				(email) => email !== user.email
			);

			if (updatedSharedWith.length > 0) {
				await updateDoc(doc(db, `teams/${teamId}/knowledgebase`, file.id), {
					sharedWith: updatedSharedWith,
				});
			} else {
				await deleteDoc(doc(db, `teams/${teamId}/knowledgebase`, file.id));
				if (file.sharedBy === user.uid) {
					const storageRef = ref(storage, file.file);
					await deleteObject(storageRef);
				}
			}

			alert("File removed from your shared files!");
			fetchFiles();
		} catch (error) {
			console.error("Error removing file: ", error);
			alert("Error removing file. Please try again.");
		}
	};

	const handleNameClick = async (file) => {
		setCurrentFile(file);
		setPopupContent(file.file);
		setIsPopupOpen(true);
		setCurrentFileSharedWith(file.sharedWith || []);
		const fileType = file.name.split(".").pop().toLowerCase();
		if (fileType === "txt") {
			setPopupType("text");
			setIsEditing(true);
			setCurrentFileId(file.id);
			setCurrentFileName(file.name);
			try {
				const response = await fetch(file.file);
				const text = await response.text();
				setEditContent(text);
			} catch (error) {
				console.error("Error fetching file content: ", error);
			}
		} else if (fileType === "pdf") {
			setPopupType("pdf");
		} else if (fileType.match(/(jpg|jpeg|png|gif)/)) {
			setPopupType("image");
		} else {
			setPopupType("other");
		}
	};

	const closePopup = () => {
		setIsPopupOpen(false);
		setPopupContent(null);
		setIsEditing(false);
		setEditContent("");
		setCurrentFileSharedWith([]);
	};

	const handleSave = async () => {
		try {
			const blob = new Blob([editContent], { type: "text/plain" });
			const storageRef = ref(
				storage,
				`teams/${teamId}/knowledgebase/${currentFileName}`
			);
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);
			const metadata = await getMetadata(storageRef);

			const fileRef = doc(db, `teams/${teamId}/knowledgebase`, currentFileId);
			await updateDoc(fileRef, {
				file: downloadURL,
				updatedAt: new Date(),
				size: metadata.size,
			});

			setIsEditing(false);
			setPopupContent(null);
			setIsPopupOpen(false);
			fetchFiles();
			alert("File updated successfully!");
		} catch (error) {
			console.error("Error saving file: ", error);
			alert("Error updating file. Please try again.");
		}
	};

	const getFileIcon = (fileName) => {
		const fileType = fileName.split(".").pop().toLowerCase();
		switch (fileType) {
			case "pdf":
				return faFilePdf;
			case "doc":
			case "docx":
				return faFileWord;
			case "xls":
			case "xlsx":
				return faFileExcel;
			case "csv":
				return faFileCsv;
			case "txt":
			case "md":
				return faFileAlt;
			case "mp3":
			case "wav":
				return faFileAudio;
			case "mp4":
			case "mov":
			case "avi":
				return faFileVideo;
			default:
				return faFileLines;
		}
	};

	const getFileColor = (fileName) => {
		if (fileName.sharedDepartments && fileName.sharedDepartments.length > 0) {
			const departmentId = fileName.sharedDepartments[0];
			const department = departments.find((dept) => dept.id === departmentId);
			if (department && department.color) {
				return department.color;
			}
		} else {
			const fileType = fileName.name.split(".").pop().toLowerCase();
			switch (fileType) {
				case "pdf":
					return "#e74c3c";
				case "doc":
				case "docx":
					return "#3498db";
				case "xls":
				case "xlsx":
					return "#2ecc71";
				case "csv":
					return "#f1c40f";
				case "txt":
				case "md":
					return "#0063c7";
				case "mp3":
				case "wav":
					return "#e67e22";
				case "mp4":
				case "mov":
				case "avi":
					return "#9b59b6";
				default:
					return "#34495e";
			}
		}
	};

	const getCardStyle = (file) => {
		if (file.sharedDepartments && file.sharedDepartments.length > 0) {
			const departmentId = file.sharedDepartments[0];
			const department = departments.find((dept) => dept.id === departmentId);
			if (department && department.color) {
				return {
					borderTop: ` 10px solid ${department.color}`,
				};
			}
		}
		return {
			display: "flex",
			flexDirection: "column",
			height: "300px",
		};
	};
	// eslint-disable-next-line
	const handleDepartmentFilterChange = (event) => {
		setSelectedDepartmentFilter(event.target.value);
	};

	const formatFileSize = (bytes) => {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
	};

	const filteredSharedFiles = sharedFiles
		.filter((file) =>
			file.name.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.filter(
			(file) =>
				!selectedDepartment ||
				selectedDepartment.id === "all" ||
				(file.sharedDepartments &&
					file.sharedDepartments.includes(selectedDepartment.id))
		);

	const renderCardView = () => (
		<Grid container spacing={4} style={{ padding: "20px" }}>
			{filteredSharedFiles.map((file) => (
				<Grid item xs={12} sm={6} md={4} key={file.id}>
					<Card className={`card-color-${switchChecked} card-border`}>
						<CardMedia
							component="div"
							style={{
								...getCardStyle(file),
								height: "140px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<FontAwesomeIcon
								icon={getFileIcon(file.name)}
								style={{
									color: getFileColor(file),
									fontSize: "3rem",
									background: "white",
									padding: "10px",
									borderRadius: "35px",
								}}
							/>
						</CardMedia>
						<CardContent
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								flexGrow: 1,
								color: switchChecked ? "black" : "white",
							}}
						>
							<Typography
								onClick={() => handleNameClick(file)}
								style={{
									cursor: "pointer",
									textAlign: "center",
									marginBottom: "8px",
									fontSize: ".9rem",
								}}
								variant="h6"
								component="p"
							>
								{file.name}
							</Typography>
							<Typography
								style={{
									fontSize: ".8rem",
									marginBottom: "16px",
									color: "white",
								}}
							>
								Size: {formatFileSize(file.size)}
							</Typography>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									marginTop: "auto",
								}}
							>
								{file.uploadedBy === user.uid && (
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => handleDeleteFile(file)}
									>
										<FontAwesomeIcon
											icon={faTrashAlt}
											style={{ marginRight: "5px" }}
										/>{" "}
										Remove
									</Button>
								)}
							</div>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);

	const renderListView = () => (
		<ul className="file-list-view">
			{filteredSharedFiles.map((file) => (
				<li key={file.id} className={`list-item-${switchChecked}`}>
					<div className="file-icon">
						<FontAwesomeIcon
							icon={getFileIcon(file.name)}
							style={{ color: getFileColor(file) }}
						/>
					</div>
					<div className="file-details">
						<span className="file-name" onClick={() => handleNameClick(file)}>
							{file.name}
						</span>
						<span className="file-size">{formatFileSize(file.size)}</span>
						{file.uploadedBy === user.uid && (
							<Button
								variant="outlined"
								color="secondary"
								size="small"
								onClick={() => handleDeleteFile(file)}
							>
								<FontAwesomeIcon icon={faTrashAlt} /> Remove
							</Button>
						)}
					</div>
				</li>
			))}
		</ul>
	);

	const renderColumnView = () => (
		<div className="column-view">
			{filteredSharedFiles.map((file) => (
				<div key={file.id} className={`column-item-${switchChecked}`}>
					<FontAwesomeIcon
						icon={getFileIcon(file.name)}
						style={{
							color: getFileColor(file),
							marginRight: "10px",
						}}
					/>
					<span className="file-name" onClick={() => handleNameClick(file)}>
						{file.name}
					</span>
					<span className="file-size">{formatFileSize(file.size)}</span>
					{file.uploadedBy === user.uid && (
						<Button
							variant="outlined"
							color="secondary"
							size="small"
							onClick={() => handleDeleteFile(file)}
						>
							<FontAwesomeIcon icon={faTrashAlt} />
						</Button>
					)}
				</div>
			))}
		</div>
	);

	if (loading) {
		return (
			<div
				className={`main-content template-content main-color-${switchChecked}`}
			>
				Loading...
			</div>
		);
	}

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<h2>Shared Knowledge Center</h2>
			<div className="knowledge-content">
				<div className="search-filter-container">
					<div className="search-section">
						<input
							type="text"
							placeholder="Search files..."
							className="search-files"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
					<FormControl
						variant="outlined"
						style={{ minWidth: 120 }}
						className="viewMode"
					>
						<InputLabel>View Mode</InputLabel>
						<Select
							value={viewMode}
							onChange={(e) => setViewMode(e.target.value)}
							label="View Mode"
						>
							<MenuItem value="cards">Cards</MenuItem>
							<MenuItem value="list">Columns</MenuItem>
							<MenuItem value="columns">List</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="file-list">
					{viewMode === "cards" && renderCardView()}
					{viewMode === "list" && renderListView()}
					{viewMode === "columns" && renderColumnView()}
				</div>
			</div>
			{isPopupOpen && (
				<div className="popup-overlay">
					<button
						onClick={() => setShowSharedWithSection(!showSharedWithSection)}
						className="knowledgebase-share-btn"
					>
						{showSharedWithSection ? "Preview" : "Show Shared With"}
					</button>
					{showSharedWithSection ? (
						<div className="shared-with-section">
							<h3>
								<FontAwesomeIcon icon={faUsers} /> Shared with:
							</h3>

							<h4 className="sharedWithTitles">Users:</h4>
							{currentFileSharedWith.length > 0 ? (
								currentFileSharedWith.map((email, index) => (
									<p key={index}>{email}</p>
								))
							) : (
								<p>This file is not shared with any individual users.</p>
							)}

							<h4 className="sharedWithTitles">Departments:</h4>
							{currentFile &&
							currentFile.sharedDepartments &&
							currentFile.sharedDepartments.length > 0 ? (
								currentFile.sharedDepartments.map((deptId) => {
									const department = departments.find((d) => d.id === deptId);
									return (
										<p key={deptId}>
											{department ? department.name : "Unknown Department"}
										</p>
									);
								})
							) : (
								<p>This file is not shared with any departments.</p>
							)}
						</div>
					) : (
						<div className="popup-content">
							<button className="close-button" onClick={closePopup}>
								<FontAwesomeIcon icon={faTimes} />
							</button>
							{popupType === "text" && isEditing && (
								<div>
									<textarea
										value={editContent}
										onChange={(e) => setEditContent(e.target.value)}
										rows="20"
										className="file-viewer"
									></textarea>
									<button onClick={handleSave}>
										<FontAwesomeIcon
											icon={faFloppyDisk}
											className="knowledgebase-share-btn"
										/>{" "}
										Save
									</button>
								</div>
							)}
							{popupType === "image" && (
								<img src={popupContent} alt="Preview" width="100%" />
							)}
							{popupType === "pdf" && (
								<iframe
									src={popupContent}
									title="PDF Preview"
									width="100%"
									height="100%"
								></iframe>
							)}
							{popupType === "other" && (
								<p>Preview not available for this file type.</p>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default SharedKnowledgeBase;
