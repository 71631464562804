import React, { useState } from "react";
import BotTemplate from "./BotTemplate";
import "./Styles/CreateBotForm.css";
import OpenAI from "openai";

// Initialize OpenAI with your API key
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const CreateBotForm = ({ user, switchChecked }) => {
	const [botName, setBotName] = useState("");
	const [instructions, setInstructions] = useState("");
	const [description, setDescription] = useState("");
	const [briefDescription, setBriefDescription] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [creationMode, setCreationMode] = useState("ai");

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsSubmitted(true);
	};

	const generateBotWithAI = async () => {
		try {
			setLoading(true);
			setError("");

			const response = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [
					{
						role: "system",
						content: `You are an AI assistant designed to generate task-specific bot configurations based on a user's brief description of their needs. Your response should be in valid JSON format without any markdown formatting.

            Your tasks include:

            Understanding User Requirements:
            - Based on the user's brief, determine the appropriate domain (e.g., customer service, event planning, data analysis, etc.).
            - Clarify any ambiguous points by asking the user for more details if necessary.

            Creating Bot Configurations:
            - Use the description provided by the user to generate a new bot configuration, similar to the existing ones such as CustomerServiceBot, DataAnalysisBot, EventPlanningBot, etc.
            - Include the following in the bot configuration:
              - Bot Name: Choose an appropriate name for the bot based on its purpose.
              - Instructions: Write a detailed set of instructions outlining the bot's purpose, tasks, and behaviors. Ensure the bot handles tasks related to the user's requirements.
              - Description: Write a brief summary of what the bot does.

            Maintaining Consistency with Existing Bots:
            - Ensure that the generated configuration is consistent with the structure of existing bots like the CustomerServiceBot or DataAnalysisBot, aligning with their formatting, task specifications, and the requirement to reference a knowledge base when relevant.

            Including Source References:
            - If the bot is expected to provide information based on an external or internal knowledge base, ensure the bot references the relevant sources in its responses.

            Finalizing and Delivering:
            - Present the generated bot configuration in a clear, user-friendly format for review.
            - Optionally, offer to modify the bot configuration based on user feedback or additional requirements.

            Your response should be in the following JSON format:
            {
              "name": "Bot Name",
              "instructions": "Detailed instructions for the bot",
              "description": "Brief description of what the bot does"
            }

            You Must Ensure that your response is valid JSON without any markdown formatting.`,
					},
					{
						role: "user",
						content: `Generate a bot configuration with name, instructions, and description based on the following: ${briefDescription}`,
					},
				],
			});

			const botData = response.choices[0].message.content;

			// Remove markdown code block if present
			const cleanedBotData = botData.replace(/^```json\n|\n```$/g, "").trim();

			// Attempt to parse the JSON response
			try {
				const botJson = JSON.parse(cleanedBotData);
				setBotName(botJson.name || "New Bot");
				setInstructions(botJson.instructions || "No instructions provided.");
				setDescription(botJson.description || "No description provided.");
				setIsSubmitted(true);
			} catch (parseError) {
				console.error("Error parsing AI response:", parseError);
				setError(
					`Failed to parse AI response. Error: ${parseError.message}. Please try again or create the bot manually.`
				);
			}
		} catch (err) {
			console.error("Error generating bot with AI:", err);
			setError(
				`Failed to generate bot. Error: ${err.message}. Please try again or create the bot manually.`
			);
		} finally {
			setLoading(false);
		}
	};

	const handleGenerateAI = (e) => {
		e.preventDefault();
		if (briefDescription.trim() === "") {
			setError("Please provide a brief description for AI generation.");
			return;
		}
		generateBotWithAI();
	};

	const toggleCreationMode = () => {
		setCreationMode(creationMode === "scratch" ? "ai" : "scratch");
	};

	return (
		<div>
			{!isSubmitted ? (
				<div className={`main-content main-color-${switchChecked}`}>
					<div className="top-bar">
						<button
							className="main-content-button"
							onClick={toggleCreationMode}
						>
							{creationMode === "scratch"
								? "Generate with AI"
								: "Create from Scratch"}
						</button>
					</div>
					{creationMode === "scratch" ? (
						<form onSubmit={handleSubmit} className="main-form">
							<div className="form-group">
								<label htmlFor="botName">Agent's Name</label>
								<input
									type="text"
									id="botName"
									className="flow-name main-flows-name"
									value={botName}
									onChange={(e) => setBotName(e.target.value)}
									required
									placeholder="Enter Custom Name"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="instructions">Custom Instructions</label>
								<textarea
									placeholder="Enter Custom Instructions as to what the Assistant's role will be ..."
									id="instructions"
									value={instructions}
									className="chat-textarea"
									onChange={(e) => setInstructions(e.target.value)}
									required
								></textarea>
							</div>
							<div className="form-group">
								<label htmlFor="description">Custom Description</label>
								<textarea
									id="description"
									placeholder="Brief description of the Assistant's actions ..."
									className="chat-textarea"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									required
								></textarea>
							</div>
							<button type="submit" className="main-content-button">
								Create Assistant
							</button>
						</form>
					) : (
						<form onSubmit={handleGenerateAI} className="main-form">
							<div className="form-group">
								<label htmlFor="briefDescription">
									Describe the Assistant You Want
								</label>
								<textarea
									placeholder="Enter a brief description of what the Assistant should do..."
									id="briefDescription"
									value={briefDescription}
									className="chat-textarea"
									onChange={(e) => setBriefDescription(e.target.value)}
									required
								></textarea>
							</div>
							<button type="submit" className="main-content-button">
								Generate with AI
							</button>
							{loading && <p>Generating bot configuration...</p>}
							{error && <p style={{ color: "red" }}>{error}</p>}
						</form>
					)}
				</div>
			) : (
				<BotTemplate
					user={user}
					botName={botName}
					instructions={instructions}
					description={description}
					switchChecked={switchChecked}
				/>
			)}
		</div>
	);
};

export default CreateBotForm;
