export const model = "gpt-4o"; // Specify the model to use
export const viewType = "prompt"; // Default view type
export const templateName = "Sales Forecasting and Strategy Agent Flow"; // Template name for the assistant
export const defaultTemplateName = "Sales Forecasting and Strategy Agent Flow";

// Array of prompt objects
export const prompts = [
	{
		id: "prompt-1",
		title: "Sales Data Collection and Analysis",
		content: `Your task is to collect and analyze historical sales data to generate insights for forecasting and strategy development. Follow these steps:
1. Collect data from all sales channels, including:
   a. Direct sales (e.g., deals closed by the sales team).
   b. E-commerce or online transactions.
   c. Product or service usage metrics (if applicable).
   
2. Organize the data based on time periods (monthly, quarterly, yearly) and segment it by product categories, regions, and customer types.

3. Identify key sales performance metrics, such as:
   a. Conversion rates.
   b. Average deal size or transaction value.
   c. Customer lifetime value (CLV).
   d. Sales cycle length.

4. Perform a trend analysis to identify patterns in sales performance (e.g., seasonal trends, growth in specific regions or product categories).

5. Present your findings in a structured report, using data visualizations (e.g., charts, graphs) to highlight key trends and sales drivers.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "action",
		url: "",
	},
	{
		id: "prompt-2",
		title: "Sales Forecasting",
		content: `Your task is to predict future sales performance based on historical data and market trends. Follow these steps:
1. Review historical sales data and trends identified in the previous step.
2. Use AI-based forecasting models to predict future sales over different time frames (e.g., next quarter, next year).
3. Incorporate external factors that could influence sales, such as:
   a. Market conditions (e.g., economic outlook, industry trends).
   b. Seasonal factors (e.g., holiday sales spikes, annual sales dips).
   c. Customer behavior changes (e.g., shift to online purchasing, demand for new features).
   
4. Generate multiple sales forecasts based on different scenarios:
   a. Optimistic (e.g., rapid market growth, increased marketing spend).
   b. Pessimistic (e.g., economic downturn, reduced customer demand).
   c. Baseline (e.g., steady growth based on current trends).

5. Present the sales forecast in a clear, visual format, highlighting key projections and any potential risks or opportunities.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-3",
		title: "Strategic Recommendations",
		content: `Your task is to develop strategic sales recommendations based on forecasted trends and data insights. Follow these steps:
1. Review the sales forecast and identify key opportunities for growth (e.g., regions, product lines, customer segments).
2. Develop specific sales strategies to capitalize on these opportunities, such as:
   a. Expanding into high-growth markets or customer segments.
   b. Offering new product bundles or service tiers.
   c. Increasing sales outreach efforts in underperforming areas.
   
3. Suggest adjustments to the sales process based on forecasted trends, including:
   a. Shortening the sales cycle by improving lead qualification.
   b. Adjusting pricing strategies to increase average deal size.
   c. Enhancing the cross-selling or upselling of related products or services.
   
4. Present detailed action plans for sales teams, including:
   a. Recommended changes to daily workflows or targets.
   b. Specific KPIs to track based on forecasted growth areas.
   c. Suggested marketing alignment to support sales efforts.

5. Conclude with a prioritized list of strategic initiatives that should be pursued in the short-term (next quarter) and long-term (next year).`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-4",
		title: "Risk Mitigation and Contingency Planning",
		content: `Your task is to assess risks to the sales forecast and develop contingency plans. Follow these steps:
1. Review the sales forecast and identify potential risks, such as:
   a. Market volatility (e.g., economic downturn, changes in customer demand).
   b. Competition (e.g., new competitors entering the market, pricing wars).
   c. Internal challenges (e.g., sales team capacity, product delays).
   
2. Rank the identified risks based on likelihood and potential impact on the sales forecast.

3. Develop contingency plans to address each major risk, such as:
   a. Introducing promotions or discounts to maintain sales during slow periods.
   b. Shifting resources toward higher-performing regions or product lines.
   c. Implementing new sales training to increase efficiency or reduce the length of the sales cycle.

4. Create risk-monitoring systems to track early warning signs of forecast deviations (e.g., sudden drops in conversion rates, changes in customer sentiment).

5. Present the risk mitigation strategies and contingency plans in a structured format, with timelines and trigger points for implementing corrective actions.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-5",
		title: "Sales Team Performance Tracking",
		content: `Your task is to develop a system for tracking the performance of the sales team in relation to the forecasted sales targets. Follow these steps:
1. Set specific, measurable KPIs based on the sales forecast, such as:
   a. Monthly and quarterly sales targets by region and product category.
   b. Conversion rate targets for leads generated through different channels.
   c. Average deal size and sales cycle length goals.
   
2. Implement a real-time performance tracking system, where sales team progress is measured against forecasted targets.

3. Create regular performance reports for the sales team, highlighting:
   a. Areas where targets are being exceeded.
   b. Areas where performance is lagging behind forecast.
   c. Recommendations for individual or team-level improvements.
   
4. Develop reward systems or incentives based on achieving or exceeding forecasted sales goals.

5. Present a comprehensive performance tracking and reporting plan, including tools and processes for measuring and sharing sales progress.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
];
