import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../firebaseConfig";
import axios from "axios";
import {
	collection,
	addDoc,
	getDocs,
	query,
	doc,
	getDoc,
	orderBy,
	limit,
	where,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./Styles/KnowledgeBase.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileArrowUp,
	faMicroscope,
	faMicrophoneLines,
	faBroom,
} from "@fortawesome/free-solid-svg-icons";

const AddKnowledgeBase = ({ user, switchChecked }) => {
	// eslint-disable-next-line
	const [files, setFiles] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [url, setUrl] = useState("");
	const [isDragActive, setIsDragActive] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [scrapedFiles, setScrapedFiles] = useState([]);
	const [voiceFiles, setVoiceFiles] = useState([]);
	const [interviewFiles, setInterviewFiles] = useState([]); // New state for Interview files
	// eslint-disable-next-line
	const [updateMessage, setUpdateMessage] = useState(0);
	const [scrapeButtonClicked, setScrapeButtonClicked] = useState(false);
	// eslint-disable-next-line
	const [teamId, setTeamId] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDoc = await getDoc(doc(db, `users/${user.uid}`));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};
		fetchUserData();
	}, [user.uid]);

	const fetchFiles = useCallback(
		async (formType, setFilesCallback) => {
			try {
				const q = query(
					collection(db, `users/${user.uid}/knowledgebase`),
					where("form", "==", formType),
					orderBy("createdAt", "desc"),
					limit(10)
				);
				const querySnapshot = await getDocs(q);
				const filesList = [];
				querySnapshot.forEach((doc) => {
					filesList.push({ id: doc.id, ...doc.data() });
				});
				setFilesCallback(filesList);
			} catch (error) {
				console.error("Error fetching files: ", error);
			}
		},
		[user.uid]
	);

	useEffect(() => {
		fetchFiles("File Upload", setUploadedFiles); // Fetch files for "File Upload"
		fetchFiles("scrape", setScrapedFiles); // Fetch files for "scrape"
		fetchFiles("Voice Drop", setVoiceFiles); // Fetch files for "Voice Drop"
		fetchFiles("Interview", setInterviewFiles); // Fetch files for "Interview"
	}, [fetchFiles]);

	const handleFileChange = async (e) => {
		const selectedFiles = Array.from(e.target.files);
		setFiles(selectedFiles);
		setFileNames(selectedFiles.map((file) => file.name));

		await handleUpload(selectedFiles);
	};

	const handleUpload = async (selectedFiles) => {
		if (selectedFiles.length === 0) return;

		try {
			for (const file of selectedFiles) {
				let storageRef = ref(
					storage,
					`users/${user.uid}/personal/${file.name}`
				);
				let dbCollection = collection(db, `users/${user.uid}/knowledgebase`);

				await uploadBytes(storageRef, file);
				const downloadURL = await getDownloadURL(storageRef);

				await addDoc(dbCollection, {
					name: file.name,
					file: downloadURL,
					createdAt: new Date(),
					uploadedBy: user.uid,
					isTeamFile: false,
					form: "File Upload",
				});
			}

			setFiles([]);
			setFileNames([]);
			fetchFiles("File Upload", setUploadedFiles); // Re-fetch files after upload
			alert("Files uploaded successfully!");
		} catch (error) {
			console.error("Error uploading file: ", error);
			alert("Error uploading files. Please try again.");
		}
	};

	const handleScrape = async () => {
		setUpdateMessage(1);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/scrapeAndStore`,
				{ url },
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			setUpdateMessage(2);

			const scrapedData = response.data;
			await new Promise((resolve) => setTimeout(resolve, 3000));

			setUpdateMessage(3);
			const scrapedText = scrapedData
				.map((item) => `URL: ${item.url}\nTEXT: ${item.text}`)
				.join("\n\n");
			const blob = new Blob([scrapedText], { type: "text/plain" });
			const fileName = `${url}-Scraped.txt`;

			const storageRef = ref(storage, `users/${user.uid}/personal/${fileName}`);
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);

			const dbCollection = collection(db, `users/${user.uid}/knowledgebase`);

			await addDoc(dbCollection, {
				name: fileName,
				file: downloadURL,
				createdAt: new Date(),
				uploadedBy: user.uid,
				isTeamFile: false,
				form: "scrape",
			});
			setUpdateMessage(4);

			fetchFiles("scrape", setScrapedFiles); // Re-fetch scraped files after upload
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setUpdateMessage(0);
			alert("Scraping completed and file added to knowledge base.");
		} catch (error) {
			console.error("Error scraping the URL:", error);
			setUpdateMessage(0);
			if (error.response) {
				alert(
					`Error scraping the URL: ${
						error.response.data.error || error.response.statusText
					}`
				);
			} else if (error.request) {
				alert(
					"Error scraping the URL: No response received from server. Please check your network connection."
				);
			} else {
				alert(`Error scraping the URL: ${error.message}`);
			}
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragActive(true);
	};

	const handleDrop = async (e) => {
		e.preventDefault();
		setIsDragActive(false);
		const droppedFiles = Array.from(e.dataTransfer.files);
		if (droppedFiles.length > 0) {
			setFiles(droppedFiles);
			setFileNames(droppedFiles.map((file) => file.name));
			await handleUpload(droppedFiles);
		}
	};

	const handleDragLeave = () => {
		setIsDragActive(false);
	};

	return (
		<div
			className={`main-content main-color-${switchChecked}`}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
			onDragLeave={handleDragLeave}
		>
			<h2>Add Knowledge</h2>
			<div className={`knowledge-content ${isDragActive ? "drag-active" : ""}`}>
				<div className="import-sections">
					{/* File Upload Section */}
					<div className="upload-section">
						<h3>File Upload</h3>
						<input
							type="file"
							id="fileupload"
							className="fileupload"
							onChange={handleFileChange}
							multiple
							accept=".txt,.pdf,.doc,.docx,.csv,.md,image/*"
						/>
						{!fileNames.length && (
							<label
								htmlFor="fileupload"
								className={`file-upload-label file-color-${switchChecked}`}
							>
								<FontAwesomeIcon icon={faFileArrowUp} /> Upload File(s)
							</label>
						)}
						{fileNames.length > 0 && (
							<div className="file-name">
								Selected files: {fileNames.join(", ")}
							</div>
						)}
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{uploadedFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Scrape Section */}
					<div className="scrape-section">
						<h3>Scrape Website</h3>
						{!scrapeButtonClicked ? (
							<div onClick={() => setScrapeButtonClicked(!scrapeButtonClicked)}>
								<button className="scrape-button">
									<FontAwesomeIcon icon={faBroom} /> Scrape Website
								</button>
							</div>
						) : (
							<div>
								<p>
									Please note that some websites are not able to be scraped due
									to legal restrictions
								</p>
								<input
									type="text"
									value={url}
									onChange={(e) => setUrl(e.target.value)}
									placeholder="Enter URL to scrape"
								/>
								<button onClick={handleScrape} className="scrape-button">
									<FontAwesomeIcon icon={faBroom} /> Scrape
								</button>
							</div>
						)}
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{scrapedFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Voice Drop Section */}
					<div className="voice-drop-section">
						<h3>Voice Drop</h3>
						<button
							onClick={() => navigate("/voice-knowledge-base")}
							className="scrape-button"
						>
							<FontAwesomeIcon icon={faMicrophoneLines} /> Voice Drop
						</button>
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{voiceFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Interview Section */}
					<div className="interview-section">
						<h3>Start Interview</h3>
						<button
							onClick={() => navigate("/interview")}
							className="scrape-button"
						>
							<FontAwesomeIcon icon={faMicroscope} /> Start Interview
						</button>
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{interviewFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddKnowledgeBase;
