import React from "react";
import { Link } from "react-router-dom";
// import "../Styles/Help.css";

const Help = ({ user, switchChecked }) => {
    return (
        <div
            className={`main-content main-color-${switchChecked} help-content`}
        >
            <h1>SpinFlow Help</h1>
            <div className={`card-color-${switchChecked} help-card`}>
                <section>
                    <h2>Getting Started</h2>
                    <p className="getting-started">
                        We recommend you that you first visit our{" "}
                        <Link to="/knowledge-base">
                            <strong>Knowledge Base</strong>
                        </Link>
                        . The Knowledge Base will contain your valuable data in
                        which can be used to train your own AI. The knowledge
                        base is stored with state of the art encryption and can
                        only be accessed from those within your team.
                    </p>
                </section>
                <section>
                    <h2>Creating Your Own Assistant</h2>
                    <p>
                        Once you've added a knowledge base, you can start
                        creating your own AI agents. Follow these steps:
                    </p>
                    <ol>
                        <li>
                            Go to the{" "}
                            <Link to="/create-assistant">
                                <strong>Create Assistant</strong>
                            </Link>{" "}
                            page.
                        </li>
                        <li>
                            Fill in the required information, such as the
                            assistant's name and description. Or select a
                            template for an helpful agent.
                        </li>
                        <li>
                            Select the knowledge base in which you want the
                            agent trained on.
                        </li>
                        <li>
                            Click the "Create" button to finalize your new
                            assistant.
                        </li>
                    </ol>
                </section>
                <section>
                    <h2>Creating Your Workflow</h2>
                    <p>To create a workflow, follow these steps:</p>
                    <ol>
                        <li>
                            Navigate to the{" "}
                            <Link to="/create-workflow">
                                <strong>Create Workflow</strong>
                            </Link>{" "}
                            page.
                        </li>
                        <li>Choose a template or start from scratch.</li>
                        <li>Define the steps and actions of your workflow.</li>
                        <li>
                            You can add more steps, and change the prompts from
                            within the windows.
                        </li>
                        <li>
                            Save your workflow and test it to ensure it works as
                            expected.
                        </li>
                    </ol>
                </section>
                <section>
                    <h2>Need More Help?</h2>
                    <p>
                        If you have any questions or need further assistance,
                        please visit our{" "}
                        <Link to="/contact-support">Contact Support</Link> page.
                        Our support team is here to help you.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default Help;
