export const model = "gpt-4o"; // Specify the model to use
export const viewType = "prompt"; // Default view type
export const templateName = "Lead Nurturing Automation Flow"; // Template name for the assistant
export const defaultTemplateName = "Lead Nurturing Automation Flow";

// Array of prompt objects
export const prompts = [
	{
		id: "prompt-1",
		title: "Lead Scoring Updates",
		content: `Your task is to reassess the lead score based on new interactions. Follow these steps:
1. Periodically check for new interactions (e.g., emails opened, demos requested).
2. Update the lead score dynamically based on the latest engagement.
3. Adjust the lead category (e.g., hot, warm, cold) if necessary.
4. Provide a summary of lead activity and how it impacts the score.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-2",
		title: "Communication Flow Customization",
		content: `Your task is to generate personalized email sequences for the lead based on their updated score. Follow these steps:
1. Review the lead’s updated score and engagement history.
2. Generate a series of tailored emails with appropriate messaging for the lead category (hot, warm, cold).
3. Schedule follow-up actions (e.g., additional emails, content recommendations) based on engagement.
4. Adjust the communication strategy based on lead responses.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-3",
		title: "Automated Messaging Agent",
		content: `Your task is to draft personalized messages and follow-up actions for the lead. Follow these steps:
1. Analyze the lead’s behavior and previous communications.
2. Draft personalized messages tailored to the lead’s needs and engagement level.
3. Schedule these messages for delivery at optimal times (e.g., immediately, in 2 days, in 1 week).
4. Recommend follow-up actions based on lead response patterns.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
	{
		id: "prompt-4",
		title: "Performance Feedback Agent",
		content: `Your task is to monitor lead responses and suggest adjustments. Follow these steps:
1. Track lead engagement after each message or interaction.
2. Adjust the lead score based on real-time feedback (e.g., opened emails, clicked links).
3. Recommend the best course of action based on the lead’s engagement.
4. Suggest when the lead should be handed over to the sales team for further follow-up.`,
		model: "gpt-4o",
		knowledgeBases: [],
		fineTunedModel: "",
		viewType: "prompt",
	},
];
